import { combineReducers } from 'redux'
import payment from './paymentReducer'
import myStatus from './myStatusReducer'
import register from './registerReducer'

export default combineReducers({
  payment,
  myStatus,
  register,
})
