import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import SectionInfo from 'components/layout/SectionInfo'
import styled from 'styled-components'
import mixin from 'styles/mixin'
import ContentsForm from '../../components/contents/ContentsForm'
import Button from 'components/common/Button'
import { contentsActions } from 'store/contents/contentsActions'
import { debounce } from 'throttle-debounce'
import { modalActions } from 'store/modal/modalActions'
import { CONTENTS_REQ_BODY } from 'models/contentsModel'
import * as R from 'ramda'

export const ContentsWrap = styled.div`
  ${mixin.clearFix()};
  flex-flow: row wrap;
`

export const FormArea = styled.div`
  margin-right: 54px;
  width: 900px;
  float: left;
`

export const PreviewArea = styled.div`
  width: 414px;
  float: right;
`

export const SubmitButtonWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
`

export const SubmitButton = styled(Button)`
  width: 220px;
  margin-left: 18px;
`

export const PreviewBorder = styled.div`
  overflow: hidden;
  border: 1px solid #d8d8d8;
`

class CreateContents extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)

    this.initialFormData = R.clone(CONTENTS_REQ_BODY)
    this.state = {
      // 업로드할 파일
      file: {
        event_image: null,
        card_photo: null,
      },
    }
  }

  /**
   * 새로운 컨텐츠 생성
   */
  handleCreate = () => {
    this.props.reqCreateContents({
      reqBody: this.props.contentsReqBody,
      fileToUpload: this.state.file,
    })
  }

  handleUpdateForm = debounce(400, reqBody => {
    this.props.setContentsReqBody(reqBody)
  })

  /**
   * 파일 변경시에는 ObjectURL을 사용하고, 실제 업로드는 나중에 하기 때문에 저장해둔다
   */
  handleChangeUploadFile = (name, file) => {
    this.setState({
      file: R.merge(this.state.file, {
        [name]: file,
      }),
    })
  }

  render() {
    return (
      <div>
        <PageInfo
          title={'신규콘텐츠 등록'}
          breadcrumbs={['콘텐츠관리', '신규콘텐츠 등록', '콘텐츠개요']}
        />

        <ContentsWrap>
          <FormArea>
            <SectionInfo
              title={'콘텐츠개요'}
              desc={`고객이 콘텐츠를 받았을 때, 처음 보여지는 페이지의 내용을 편집합니다.`}
            />

            <ContentsForm
              onUpdateForm={this.handleUpdateForm}
              initialData={this.initialFormData}
              showConfirm={this.props.showConfirm}
              onChangeFileToUpload={this.handleChangeUploadFile}
            />

            <SubmitButtonWrap>
              <SubmitButton onClick={this.handleCreate}>신규 등록</SubmitButton>
            </SubmitButtonWrap>
          </FormArea>

          <PreviewArea>
            <SectionInfo
              title={'미리보기'}
              desc={`콘텐츠가 어떻게 보여지는지 확인합니다.`}
            />
            <PreviewBorder />
          </PreviewArea>
        </ContentsWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsReqBody: state.contents.reqBody,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
      reqCreateContents: contentsActions.reqCreateContents,
      setContentsReqBody: contentsActions.setContentsReqBody,
      setFileToUpload: contentsActions.setFileToUpload,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateContents)
