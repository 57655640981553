import * as R from 'ramda'
import { createActions } from 'redux-actions'
import { Quiz, QuizReqBody, QuizListReqOption } from 'models/quizModel'
import { ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import QuizAPI from 'api/QuizAPI'
import { push, replace } from 'connected-react-router'
import { makeAsyncType } from 'utils/redux'
import { dotPath } from 'utils/ramda'
import { modalActions } from 'store/modal/modalActions'

export const types = {
  ...makeAsyncType('LIST_QUIZ'),
  ...makeAsyncType('READ_QUIZ'),
  ...makeAsyncType('CREATE_QUIZ'),
  ...makeAsyncType('UPDATE_QUIZ'),
  ...makeAsyncType('DELETE_QUIZ'),

  ...makeAsyncType('SORT_QUIZ'),

  SET_QUIZ_REQ_BODY: 'SET_QUIZ_REQ_BODY',
  TOGGLE_QUIZ_FEEDBACK_MODAL: 'TOGGLE_QUIZ_FEEDBACK_MODAL',
}

export const quizActions = createActions({
  [types.REQ_LIST_QUIZ]: (option: QuizListReqOption) => option,
  [types.REQ_LIST_QUIZ_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_QUIZ_FAIL]: undefined,

  [types.REQ_READ_QUIZ]: (qid: string) => qid,
  [types.REQ_READ_QUIZ_DONE]: (d: Quiz) => d,
  [types.REQ_READ_QUIZ_FAIL]: undefined,

  [types.REQ_CREATE_QUIZ]: (payload: { reqBody: QuizReqBody }) => payload,
  [types.REQ_CREATE_QUIZ_DONE]: (d: Quiz) => d,
  [types.REQ_CREATE_QUIZ_FAIL]: undefined,

  [types.REQ_UPDATE_QUIZ]: (payload: { qid: number, reqBody: QuizReqBody }) =>
    payload,
  [types.REQ_UPDATE_QUIZ_DONE]: undefined,
  [types.REQ_UPDATE_QUIZ_FAIL]: undefined,

  [types.REQ_DELETE_QUIZ]: (p: {
    qid: string,
    contentsId: string,
    redirectToList: boolean,
  }) => p,
  [types.REQ_DELETE_QUIZ_DONE]: undefined,
  [types.REQ_DELETE_QUIZ_FAIL]: undefined,

  [types.REQ_SORT_QUIZ]: (payload: {
    qid: number,
    reqBody: {
      eid: number,
      sort_order: number,
    },
  }) => payload,
  [types.REQ_SORT_QUIZ_DONE]: undefined,
  [types.REQ_SORT_QUIZ_FAIL]: undefined,

  [types.SET_QUIZ_REQ_BODY]: (reqBody: QuizReqBody) => reqBody,
  [types.TOGGLE_QUIZ_FEEDBACK_MODAL]: undefined,
})

function* quizList() {
  yield takeLatest(types.REQ_LIST_QUIZ, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => state.quiz.list.option),
        payload
      )
      const data = yield call(QuizAPI.readList, option)
      yield put(quizActions.reqListQuizDone(data))
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqListQuizFail(e))
    }
  })
}

function* readQuiz() {
  yield takeLatest(types.REQ_READ_QUIZ, function*({ payload }) {
    try {
      yield put(quizActions.reqReadQuizDone({})) // emtpy data
      const data = yield call(QuizAPI.readItem, payload)
      yield put(quizActions.reqReadQuizDone(data))
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqReadQuizFail(e))
    }
  })
}

function* createQuiz() {
  yield takeLatest(types.REQ_CREATE_QUIZ, function*({ payload }) {
    try {
      const { reqBody } = payload
      const data = yield call(QuizAPI.createItem, reqBody)
      yield put(quizActions.reqCreateQuizDone(data))

      yield put(
        modalActions.showAlert({
          i18nKey: '저장되었습니다',
        })
      )

      // 목록으로 돌아감
      yield put(push(`/contents/edit/${reqBody.eid}/quiz/list`))
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqCreateQuizFail(e))
    }
  })
}

function* updateQuiz() {
  yield takeLatest(types.REQ_UPDATE_QUIZ, function*({ payload }) {
    try {
      const { qid, reqBody } = payload
      yield call(QuizAPI.updateItem, qid, reqBody)
      yield put(quizActions.reqUpdateQuizDone())

      yield put(
        modalActions.showAlert({
          i18nKey: '저장되었습니다',
        })
      )

      // 퀴즈 상세 업데이트. API 호출하지 않고 reqData를 덮어쓴다
      const quizDetail = yield select(state =>
        dotPath('quiz.detail.data', state)
      )
      yield put(quizActions.reqReadQuizDone(R.merge(quizDetail, reqBody)))
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqUpdateQuizFail(e))
    }
  })
}

function* deleteQuiz() {
  yield takeLatest(types.REQ_DELETE_QUIZ, function*({ payload }) {
    const { contentsId, qid, redirectToList } = payload

    try {
      yield call(QuizAPI.deleteItem, qid)
      yield put(quizActions.reqDeleteQuizDone())

      // 퀴즈 수정중이었다면 목록으로 돌아간다.
      if (redirectToList) {
        yield put(push(`/contents/edit/${contentsId}/quiz/list`))
      } else {
        // 페이지 새로고침
        yield put(replace(`/contents/edit/${contentsId}/quiz/list`))
      }
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqDeleteQuizFail(e))
    }
  })
}

function* sortQuiz() {
  yield takeLatest(types.REQ_SORT_QUIZ, function*({ payload }) {
    const { qid, reqBody } = payload
    try {
      yield call(QuizAPI.sortItem, qid, reqBody)
      yield put(quizActions.reqSortQuizDone())
    } catch (e) {
      console.error(e)
      yield put(quizActions.reqSortQuizFail(e))
    }
  })
}

export function* quizSaga(): Generator<any, any, any> {
  yield all([
    quizList(),
    readQuiz(),
    createQuiz(),
    updateQuiz(),
    deleteQuiz(),
    sortQuiz(),
  ])
}
