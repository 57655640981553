/**
 * 최대 길이가 지정되어 있다면 조건을 만족할 때만 콜백을 실행한다.
 */
export const onChangeWithMaxLen = (fn, maxLen) => e => {
  const { value } = e.target

  if (typeof fn === 'function') {
    if (maxLen && value.length <= maxLen) {
      fn(e)
    } else {
      fn(e)
    }
  }
  return e
}

export const isFunction = fn => typeof fn === 'function'
