import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { addHyphenToCellPhone, cdnImage } from 'utils/string'
// eslint-disable-next-line
import { isFunc } from 'utils/validator'
import { isFalsey } from 'utils/isTruthy'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #dee0e4;
  padding: 0 12px;
  font-size: 14px;

  & > input {
    flex: 1;
    border: none;
    background-color: transparent;
    font-size: inherit;
    height: 80%;

    &::placeholder {
      color: #727272;
    }
  }
`

type IconType = 'PASSWORD' | 'ID' | 'PHONE'

const getIconUrl = (icon: IconType) => {
  const iconUrl = {
    PASSWORD: cdnImage('icon-login-password.png'),
    ID: cdnImage('icon-login-id.png'),
    PHONE: cdnImage('icon-input-phone.png'),
  }

  return iconUrl[icon]
}

const Icon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

type Props = {
  style?: Object,
  icon: IconTypes,
  value: string,
  onChange: Function,
  placeholder: string,
  type: string,
}

class Input extends React.Component<Props, null> {
  static defaultProps = {
    onChange: () => {},
  }

  /**
   * Chrome 브라우저에서 input type이 email이면 trim이 적용되지 않음.
   */
  handleChange = e => {
    const isValueEmpty = isFalsey(R.trim(e.target.value))

    if (isValueEmpty) {
      e.target.value = R.trim(e.target.value) // 공백 제거
    }

    const isCallbackEnabled = this.props.maxLen
      ? R.length(e.target.value) <= this.props.maxLen
      : true

    if (isCallbackEnabled) {
      this.props.onChange(e)
    }
  }

  render() {
    const {
      style, // css style
      icon, // 아이콘 타입
      iconSize = '20px', // 좌측 아이콘 크기
      value,
      onBlur,
      placeholder,
      type, // html type
      children, // 인풋 오른쪽 영역, 검색
    } = this.props

    return (
      <Wrap style={style}>
        {!!icon ? (
          <Icon
            src={getIconUrl(icon)}
            style={{ width: iconSize, height: iconSize }}
          />
        ) : null}

        <input
          type={type}
          value={value || ''}
          onChange={this.handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
        />

        {children}
      </Wrap>
    )
  }
}

export const PhoneInput = ({ onChange = () => {}, onBlur, ...rest }) => {
  const onChangeInput = e => {
    const { value } = e.target
    const isValidPhoneInput = !/[^0-9-]/.test(value)

    if (isValidPhoneInput) {
      onChange(addHyphenToCellPhone(value))
    }
  }

  const onBlurInput = e => {
    const phonenum = addHyphenToCellPhone(e.target.value)
    if (typeof onBlur === 'function') {
      onBlur(phonenum)
    } else {
      onChange(phonenum)
    }
  }

  return (
    <Input
      {...rest}
      onChange={onChangeInput}
      onBlur={onBlurInput}
      maxLen={13} // 하이픈 포함 11자리
    />
  )
}

export default Input
