import { createActions } from 'redux-actions'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import * as R from 'ramda'
import { makeAsyncType } from 'utils/redux'
import { ListCallData, ReadListCallOption } from 'store/commonState/list'
import MypageAPI from 'api/MypageAPI'
import { modalActions } from 'store/modal/modalActions'

export const types = {
  ...makeAsyncType('READ_MY_STATUS'), // 마이페이지 서비스 이용현황 + 사용자 정보 데이터
  ...makeAsyncType('UPDATE_MY_STATUS'),
  ...makeAsyncType('LIST_CHECKOUT_HISTORY'),
  ...makeAsyncType('READ_MY_POINT'),
  ...makeAsyncType('CHARGE_POINT'),
}

export const mypageActions = createActions({
  [types.REQ_READ_MY_STATUS]: undefined,
  [types.REQ_READ_MY_STATUS_DONE]: undefined,
  [types.REQ_READ_MY_STATUS_FAIL]: undefined,

  [types.REQ_READ_MY_POINT]: undefined,
  [types.REQ_READ_MY_POINT_DONE]: undefined,
  [types.REQ_READ_MY_POINT_FAIL]: undefined,

  [types.REQ_UPDATE_MY_STATUS]: (p: {
    name: string,
    phone: string,
    agree_sms: boolean,
    agree_email: boolean,
  }) => p,
  [types.REQ_UPDATE_MY_STATUS_DONE]: undefined,
  [types.REQ_UPDATE_MY_STATUS_FAIL]: undefined,

  [types.REQ_LIST_CHECKOUT_HISTORY]: (option: ReadListCallOption) => option,
  [types.REQ_LIST_CHECKOUT_HISTORY_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_CHECKOUT_HISTORY_FAIL]: undefined,

  [types.REQ_CHARGE_POINT]: (option: {
    point: number,
    onSuccess: Function,
    onFail: Function,
  }) => option,

  [types.REQ_CHARGE_POINT_DONE]: undefined,
  [types.REQ_CHARGE_POINT_FAIL]: undefined,
})

function* readMyStatus() {
  yield takeLatest(types.REQ_READ_MY_STATUS, function*({ payload = {} }) {
    try {
      const data = yield call(MypageAPI.readMyStatus)
      yield put(mypageActions.reqReadMyStatusDone(data))
    } catch (e) {
      yield put(mypageActions.reqReadMyStatusFail())
      console.error(e)
    }
  })
}

function* updateMyStatus() {
  yield takeLatest(types.REQ_UPDATE_MY_STATUS, function*({ payload }) {
    try {
      yield call(MypageAPI.updateMyStatus, payload)
      yield put(mypageActions.reqUpdateMyStatusDone())
      yield put(
        modalActions.showAlert({
          i18nKey: '저장되었습니다',
        })
      )

      // 업데이트된 데이터를 서버에서 다시 호출
      yield put(mypageActions.reqReadMyStatus())
    } catch (e) {
      yield put(mypageActions.reqUpdateMyStatusFail())
      console.error(e)
    }
  })
}

function* checkoutHistoryList() {
  yield takeLatest(types.REQ_LIST_CHECKOUT_HISTORY, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => state.mypage.checkoutHistory.list.option),
        payload
      )
      const data = yield call(MypageAPI.readCheckoutHistoryList, option)
      yield put(mypageActions.reqListCheckoutHistoryDone(data))
    } catch (e) {
      console.error(e)
      yield put(mypageActions.reqListCheckoutHistoryFail())
    }
  })
}

function* chargePoint() {
  yield takeLatest(types.REQ_CHARGE_POINT, function*({ payload }) {
    const { point, onSuccess = () => {}, onFail = () => {} } = payload

    try {
      yield call(MypageAPI.chargePoint, { point })
      yield put(mypageActions.reqChargePointDone())
      yield put(mypageActions.reqReadMyStatus())
      onSuccess()
    } catch (e) {
      onFail()
      yield put(mypageActions.reqChargePointFail())
      console.error(e)
    }
  })
}

export function* mypageSaga() {
  yield all([
    readMyStatus(),
    updateMyStatus(),
    checkoutHistoryList(),
    chargePoint(),
  ])
}
