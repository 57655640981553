import React from 'react'
import ReactDOM from 'react-dom'
import injectTapEventPlugin from 'react-tap-event-plugin'
import { AppContainer } from 'react-hot-loader'
import Root from './Root'
import moment from 'moment'
import { initAxiosClient } from 'utils/axiosClient'
import { getAuthTokenFromStorage } from 'store/auth/authActions'
import 'react-app-polyfill/ie11'
import 'core-js'
import 'blueimp-canvas-to-blob'
import 'simplebar/dist/simplebar.min.css'
import 'cropperjs/dist/cropper.css'
import jQuery from 'jquery'

window.$ = window.jQuery = jQuery
moment.locale('ko')
require('react-hot-loader/patch')

/**
 * Http 요청을 위한 axios client 초기화
 * 자동 로그인이 설정되어 있다면 토큰이 저장되어 있을 것이므로 가져와서 사용한다.
 */
initAxiosClient(getAuthTokenFromStorage())

// Render the main app react component into the app div.
// For more details see: https://facebook.github.io/react/docs/top-level-api.html#react.render
const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    window.document.getElementById('root')
  )
}

render(Root)

if (module.hot) {
  module.hot.accept('./Root', () => {
    render(Root)
  })
}

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin()
