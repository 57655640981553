import React from 'react'
import * as R from 'ramda'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import CubeSpinner from 'components/common/CubeSpinner'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
import { modalActions } from 'store/modal/modalActions'
import axiosClient from 'utils/axiosClient'
import { dotPath } from 'utils/ramda'
import { textLineBreak } from 'components/common/textLineBreak'
import { toastActions } from 'store/toast/toastActions'
import { isValidEmail } from 'utils/validator'

/**
 * 로그인
 * 비밀번호 재설정을 위해 이메일 입력
 *
 * @class CheckEmail
 * @extends {React.Component}
 */
class CheckEmail extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      isLoading: false,
    }
  }

  handleClickNext = e => {
    e.preventDefault()

    const { isValid, message } = isValidEmail(this.state.email)

    if (R.not(isValid)) {
      this.props.showAlert({
        i18nKey: message || '올바르지 않은 메일형식입니다',
      })
      return
    }

    this.setState({ isLoading: true })

    axiosClient()
      .post(`/api/passwordEmail`, {
        email: this.state.email,
      })
      .then(() => {
        this.props.history.push(`/login/email-sent`)
      })
      .catch(e => {
        console.error(e.response)

        const errorMsgContent = () =>
          textLineBreak(
            dotPath('response.data.message', e) ||
              '이메일을 전송하지 못했습니다.'
          )

        this.props.showAlert({
          content: errorMsgContent,
        })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      <Centering>
        <CubeSpinner isVisible={this.state.isLoading} />
        <RegisterContentBox>
          <PageTitle>비밀번호 재설정</PageTitle>
          <form onSubmit={this.handleClickNext}>
            <RegisterInput
              icon="ID"
              placeholder="가입시 등록한 이메일 주소를 입력하세요"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />
            <p
              style={{
                fontSize: '14px',
                color: '#727272',
                marginTop: '26px',
                marginBottom: '26px',
              }}
            >
              가입하신 이메일(아이디)로 비밀번호 재설정 메일을 보내드립니다.
            </p>
            <Button type="submit">다음으로</Button>
          </form>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      showToast: toastActions.showToast,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckEmail)
