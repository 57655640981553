export const modalType = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
  FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
}

export const storageKey = {
  ADMIN_AUTH_TOKEN: 'ADMIN_AUTH_TOKEN',
  USER_NAME: 'USER_NAME',
  QB_ADMIN_USER: 'qb_admin_user',
  HIDE_DASHBOARD_SAMPLE_ALERT: 'HIDE_DASHBOARD_SAMPLE_ALERT',
}

export const contentsTabName = {
  INFO: 'INFO', // 기본
  QUIZ: 'QUIZ', // 퀴즈
  APPLY: 'APPLY', // 상담신청
}

export const MAX_QUIZ_COUNT = 5 // 만들 수 있는 최대 퀴즈 수
export const QUIZ_FEEDBACK_MAX_LEN = 209 // 퀴즈 해설 최대 길이

export const IMAGE_FILE_REGEX = /^image\/(png|jpg|jpeg|gif)$/i
export const VIDEO_FILE_REGEX = /^video\/.+$/i

export const messages = {
  'no options': '선택 가능한 옵션이 없습니다.',
}

export const MEGA_IN_BYTE = 1048576
export const KILO_IN_BYTE = 1024

// 이미지 파일 사이즈 제한
export const IMAGE_BYTESIZE_LIMIT = MEGA_IN_BYTE * 2

export const LICENSE_PRICE_MONTHLY = 33000 // 월 이용료

export const DATE_FORMAT_DAY_AND_TIME = 'YYYY-MM-DD HH:mm'
export const FREE_LICENSE_DAYS = 14 // 무료 이용기간
export const DAYS_IN_SEC = 60 * 60 * 24

export const VALID_BYTE_SIZE_OF_USERNAME = 36 // UTF-8에서 한글 1자 3바이트.

// 이미지 크기
export const CONTENTS_MAIN_IMAGE_WIDTH = 414
export const CONTENTS_MAIN_IMAGE_HEIGHT = 360

export const QUIZ_IMAGE_WIDTH = 414
export const QUIZ_IMAGE_HEIGHT = 350

export const BUSINESS_CARD_IMAGE_WIDTH = 106
export const BUSINESS_CARD_IMAGE_HEIGHT = 130
