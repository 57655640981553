import * as R from 'ramda'
import { types } from './authActions'
import { handleActions } from 'redux-actions'
import { User, USER } from 'models/authModel'

const STATE = {
  user: R.clone(USER),
  isLoading: false,
}

const initState = () => R.clone(STATE)

const reducer = handleActions(
  {
    [types.REQ_LOGIN]: state => {
      return R.merge(state.user, {
        isLoading: true,
      })
    },
    [types.REQ_LOGIN_FAIL]: initState,
    [types.REQ_LOGIN_DONE]: (state: STATE, { payload }: { payload: User }) => {
      return R.merge(state, {
        user: payload,
        isLoading: false,
      })
    },
    [types.LOGOUT]: initState,

    [types.SET_USER]: (state, { payload }) => {
      return R.merge(state, {
        user: payload,
      })
    },
    [types.SET_LOGIN_IS_ON_PROCESS]: (state, { payload }) => {
      return R.merge(state, {
        isLoading: payload,
      })
    },
  },
  STATE
)

export default reducer
