import * as R from 'ramda'
import { User } from 'models/authModel'

/**
 * 유저가 슈퍼 유저인지 확인한다.
 * authorities 배열 멤버 객체의 authority 필드에 권한이 할당되어 있다.
 * @param {User} user
 */
export const isSuperUserSelector = (user: User): boolean => {
  const authorities = R.prop('authorities', user)
  if (R.not(R.empty(authorities))) {
    const authorityList = R.map(a => a.authority, authorities)
    return R.contains('SU', authorityList)
  } else {
    return false
  }
}
