import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Modal from 'components/modal/Modal'
import styled from 'styled-components'
import { termsActions } from 'store/terms/termsActions'

export const TermsModalBody = styled.div`
  background: #fff;
`

/**
 * 공통으로 사용할 컨텐츠 약관 컨텐츠
 */
export const TermsModalContents = styled.div`
  width: 1100px;
  padding: 60px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* background-color: #d8d8d8; */
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    margin-top: 35px;
  }

  p {
    color: #374146;
    & + & {
      margin-top: 1rem;
    }
  }

  a {
    color: #1f5de6;
    text-decoration: underline;
  }

  .link {
    color: #1f5de6;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
  ul {
    margin: 35px 0;
    padding-left: 2rem;
  }

  li {
    margin: 1rem 0;
  }

  table {
    width: 100%;
    border: 1px solid #dddddd;
    border-collapse: collapse;
    th,
    td {
      text-align: left;
      padding: 15px 15px;
      border: 1px solid #dddddd;
      vertical-align: top;
    }

    th {
      font-weight: bold;
    }
  }
`

class CompanyIntroModal extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.reqReadCompanyIntro()
    }
  }

  render() {
    const { isOpen, onClose, ...rest } = this.props

    return (
      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <TermsModalBody>
          <TermsModalContents
            dangerouslySetInnerHTML={{ __html: this.props.terms }}
          />
        </TermsModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  terms: state.terms.companyIntro,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadCompanyIntro: termsActions.reqReadCompanyIntro,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanyIntroModal)
