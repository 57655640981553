import React from 'react'
import Select from 'react-styled-select' //https://www.npmjs.com/package/react-styled-select
import styled from 'styled-components'

const WrappedSelect = styled(Select)`
  min-width: 150px;
  border: 1px solid #efefef;

  --styled-select-control__min-height: 30px;
  --styled-select-menu__max-height: 300px;

  --styled-select-input__height: 20px;

  --styled-select-placeholder__color: #999;
  --styled-select__color: gray;
  --styled-select__background-color: white;
  --styled-select__border-color: skyblue;
  --styled-select__border-width: 1px;
  --styled-select-clear__heught: 20px;

  --styled-select-placeholder__line-height: 26px;

  --styled-select-value__line-height: 26px;
  --styled-select-value__font-size: ${props => props.fontSize || '12px'};

  --styled-select-menu-outer__margin: 0 0 0 0;
  --styled-select-menu-outer__padding: 0;
  --styled-select-menu-outer__background-color: #555;
  --styled-select-menu-outer__border-color: skyblue;
  --styled-select-menu-outer__border-style: solid;
  --styled-select-menu-outer__border-width: 0px;

  --styled-select-option__background-color: white;
  --styled-select-option__background-color--focused: lightgray;
  --styled-select-option__background-color--selected: gray;
  --styled-select-option__color--focused: black;
  --styled-select-option__color--selected: white;
  --styled-select-option__min-height: 200px;
`

export default function StyledSelect({ children, css, ...rest }) {
  return <WrappedSelect css={css} {...rest} />
}
