import React from 'react'
import styled from 'styled-components'
import RouterLink from 'components/common/RouterLink'
import { withRouter } from 'react-router-dom'

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1px;
  padding-top: 1px;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: center;
`

const Text = styled.p`
  text-align: center;
  font-weight: 100;
  font-size: 3rem;
`

const Link = styled(RouterLink)`
  margin-top: 10px;
  color: #394e7e;
  text-decoration: underline;
`

const NotFound = ({ history }) => {
  return (
    <FlexWrap>
      <Text>404 NOT FOUND</Text>
      <Link to="" onClick={() => history.goBack()}>
        go back
      </Link>
    </FlexWrap>
  )
}

export default withRouter(NotFound)
