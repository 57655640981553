import styled from 'styled-components'
import * as R from 'ramda'
import React, { Component } from 'react'

const TextAreaStyled = styled.textarea`
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  border: solid 1px #dee0e4;
  padding: 12px;
  font-size: 1rem;

  &::placeholder {
    color: #727272;
  }
`

class TextArea extends Component {
  handleChange = e => {
    if (this.props.maxLen) {
      if (R.length(e.target.value) <= this.props.maxLen) {
        this.props.onChange(e)
      }
    } else {
      this.props.onChange(e)
    }
  }

  render() {
    return <TextAreaStyled {...this.props} onChange={this.handleChange} />
  }
}

export default TextArea
