/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import * as R from 'ramda'
import Paginator from 'components/common/Paginator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import styled from 'styled-components'
import StyledSelect from 'components/form/StyledSelect'
import { dotPath } from 'utils/ramda'
import { contentsActions } from 'store/contents/contentsActions'
import { userDbActions } from 'store/userDb/userDbActions'
import { isReqformResultSelector } from 'store/userDb/userDbReducer'
import Table, { TableHeader } from 'components/common/Table'
import { SubmitButtonWrap } from 'pages/contents/CreateContents'
import { modalActions } from 'store/modal/modalActions'
import { addCommaToNum } from 'utils/string'
import format from 'date-fns/format'
import { messages, DATE_FORMAT_DAY_AND_TIME } from 'constant'
import classnames from 'classnames'
import { centeredXObj } from 'styles/mixin/centered'
import axiosClient from 'utils/axiosClient'
import { spinnerActions } from 'store/spinner/spinnerActions'
import localStorage from 'utils/localStorage'
import { selectDbContentsId } from 'store/userDb/userDbSelector'
import { toastActions, showErrorToast } from 'store/toast/toastActions'
import { debounce } from 'throttle-debounce'
import localStorageKeys from 'constant/localStorageKeys'
import CubeSpinner from 'components/common/CubeSpinner'
import Input from 'components/form/Input'
import Button from 'components/common/Button'

const ContentsSelectWrap = styled.div`
  width: 400px;
`
const MemoSelectWrap = styled.div`
  padding: 5px;
`
const TableTheadSort = styled.th`
  min-width: 100px;
  font-weight: bold;
  cursor: pointer;
`
const Sharp = styled.img`
  margin-right: -14px;
  margin-bottom: -2px;
`

export const FormInput = styled.input`
  width: 80px;
  display: block;
  font-size: 12px;
  height: 32px;
  border: none;

  padding: 6 6;
  border-bottom: 1px solid #d8d8d8;
  background-color: transparent;
  &::placeholder {
    color: black;
  }
`

const MemoInputForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;

  input {
    width: calc(100% - 45px);
    border: 1px solid #eee;
    margin-right: 5px;
    height: 25px;
    padding: 5px;
    color: #333;
  }

  button {
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 50px;
    height: 25px;
    background: #efefef;
  }
`

const ColRegion = styled.div`
  & > * {
    display: inline-block;
    &:not(:first-child) {
      margin-left: 0.5em;
    }
  }
`

class UserDbList extends React.Component {
  static propTypes = {}

  MAX_LEFT_DAY_TO_BUY = 30 // db 구매 가능한 기간

  constructor(props) {
    super(props)
    this.state = {
      isNotPayedOnly: false, // 구매했던 고객DB를 제외하기
      isSortByPhone: false, // 같은 상담고객 하나로 묶기
      isEveryDbChecked: false,
      selectedContents: null, // 선택된 select 옵션
      userDbToCheckout: [], // 구입할 DB 목록
      currentPage: null,
      groupInfo: [], // [0,0,1,1,0,0,0,...]
      SortbyAgreeTime: {
        isVisible: true,
        isAsc: false,
      },
      dbList: props.userDbListData,
      SortbyRestTime: {
        isVisible: false,
        isAsc: false,
      },
      SortbyScore: {
        isVisible: false,
        isAsc: false,
      },
      SortbyCount: {
        isVisible: false,
        isAsc: false,
      },
      SortbyShareCount: {
        isVisible: false,
        isAsc: false,
      },
      title: '',
      contentsId: null, // 현재 선택된 컨텐츠(이벤트) 아이디

      memoInputOpenId: undefined, // 메모직접입력. userdb 아이디를 입력
      memoInputOpenDb: undefined, // 메모직접입력. userdb 아이디를 입력
      memoInput: '', // 직접 입력 메모
    }
  }

  componentDidMount() {
    // 컨텐츠 목록
    this.props.reqListContents({ sort: 'eid' })

    // 조회가능 수량
    this.props.reqDbOpenCount()
    this.props.reqDbPrice()

    // DB 조회를 위한 콜백 실행. 초기 로딩시 로컬스토리지에 저장된 값을 사용한다.
    this.handleSelectContent(this.contentsIdOnStorage)

    // redux에 저장된 옵션을 그대로 사용한다.
    const initialListOption = R.clone(this.props.userDbList.option)

    this.setState({
      isNotPayedOnly: initialListOption.payed === 'false',
      isSortByPhone: initialListOption.sort === 'phone',
      isEveryDbChecked: false,
      currentPage: initialListOption.page,
    })
  }

  get contentsSelectOptions() {
    return Array.isArray(this.props.contentsListData)
      ? this.props.contentsListData.map(content => ({
          value: content.eid,
          label: content.title,
        }))
      : []
  }

  get contentsIdOnStorage() {
    return localStorage.get(localStorageKeys.contents_eid)
  }

  get memoValues() {
    return [
      '인정',
      '거부',
      '본인아님',
      '미성년자',
      '다음에통화 : 긍정적',
      '다음에통화 : 부정적',
      '부재중',
      '직접 입력',
    ]
  }

  get memoOptions() {
    return this.memoValues.map(value => ({
      value,
      label: value,
    }))
  }

  saveContentsIdToStorage = eid => {
    localStorage.set(localStorageKeys.contents_eid, eid)
  }

  removeContentId = () => {
    localStorage.remove(localStorageKeys.contents_eid)
  }

  saveContentTitle = title => {
    this.setState({ title })
    localStorage.set(localStorageKeys.contents_title, title)
  }

  removeContentTitle = () => {
    this.setState({ title: null })
    localStorage.remove(localStorageKeys.contents_title)
  }

  initPaginator = () => {
    this.setState({ currentPage: 0 })
  }

  handleChangePage = ({ page }) => {
    this.setState({
      userDbToCheckout: [], // 선택한 db초기화
      currentPage: page,
    })

    this.props.reqListUserDb({
      page,
    })
  }

  /**
   * 컨텐츠 선택.
   * 디비 목록 호출 및 로컬 스토리지에 이벤트 정보 저장
   */
  handleSelectContent = value => {
    this.props.reqListUserDb({
      page: 0,
      eid: value,
    })

    this.initPaginator()

    if (value) {
      this.saveContentsIdToStorage(value)

      const contentOptionSelected = this.contentsSelectOptions.find(
        o => o.value === value
      )

      if (contentOptionSelected) {
        this.saveContentTitle(contentOptionSelected.label)
      }
    }
  }

  /**
   * 컨텐츠 선택 해제
   */
  handleClearContent = () => {
    this.props.reqListUserDb({ page: 0, eid: null })
    this.removeContentId()
    this.removeContentTitle()
    this.initPaginator()
  }

  downloadXls = () => {
    const filename = `${this.state.title || '전체결과'} (${format(
      new Date(),
      'YYYYMMDD_HHmm'
    )})`

    this.props.showSpinner()

    axiosClient()
      .get(`/api/xls`, {
        params: {
          order: 'DESC',
          sort: 'agree_date',
          eid: this.props.userDbContentsId,
        },
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename + '.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(e => {
        this.props.showAlert({
          content: '오류가 발생했습니다.',
        })
        console.error(e)
      })
      .finally(() => {
        this.props.hideSpinner()
      })
  }

  memoupdate = debounce(300, async param => {
    try {
      await axiosClient().post(`/api/memoupdate`, param)
      this.props.showToast({
        content: '메모를 수정했습니다.',
      })
      this.props.reqListUserDb()
    } catch (e) {
      console.error(e)
      this.props.showErrorToast({
        content: '오류가 발생했습니다.',
      })
      console.error(e)
    }
  })

  handleChangeMemo = async (userDb, memo) => {
    if (memo === '직접 입력') {
      // 메모 직접 입력을 위한 폼 표시
      this.setState({ memoInputOpenDb: userDb })
    } else {
      this.setState({ memoInputOpenDb: undefined })

      const param = {
        id: userDb.id,
        type: userDb.type,
        memo: memo,
      }
      await this.memoupdate(param)
    }
  }

  handleChangeMemoText = e => {
    this.setState({ memoInput: e.target.value })
  }

  handleSaveMemoText = async e => {
    e.preventDefault()

    const param = {
      id: this.state.memoInputOpenDb.id,
      type: this.state.memoInputOpenDb.type,
      memo: this.state.memoInput,
    }

    await this.memoupdate(param)

    this.setState({
      memoInput: '',
      memoInputOpenDb: undefined,
    })
  }

  handleChangeAgreetimeSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isAsc: !this.state.SortbyAgreeTime.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyAgreeTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'agree_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'agree_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleChangeResttimeSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isAsc: !this.state.SortbyRestTime.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyRestTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'time',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'time',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleAgreetimeArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: true,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: false,
        }),
        SortbyCount: R.merge(this.state.SortbyCount, {
          isVisible: false,
        }),
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isVisible: false,
        }),
        SortbyScore: R.merge(this.state.SortbyScore, {
          isVisible: false,
        }),
      },
      () => {
        if (this.state.SortbyAgreeTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'agree_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'agree_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleResttimeArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: false,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: true,
        }),
        SortbyCount: R.merge(this.state.SortbyCount, {
          isVisible: false,
        }),
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isVisible: false,
        }),
        SortbyScore: R.merge(this.state.SortbyScore, {
          isVisible: false,
        }),
      },
      () => {
        if (this.state.SortbyRestTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'time',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'time',
            order: 'DESC',
          })
        }
      }
    )
  }

  // 점수 소팅
  handleChangeScoreSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyScore: R.merge(this.state.SortbyScore, {
          isAsc: !this.state.SortbyScore.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyScore.isAsc) {
          this.props.reqListUserDb({
            sort: 'eid',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'eid',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleScoreArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: false,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: false,
        }),
        SortbyCount: R.merge(this.state.SortbyCount, {
          isVisible: false,
        }),
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isVisible: false,
        }),
        SortbyScore: R.merge(this.state.SortbyScore, {
          isVisible: true,
        }),
      },
      () => {
        if (this.state.SortbyScore.isAsc) {
          this.props.reqListUserDb({
            sort: 'ed',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'ed',
            order: 'DESC',
          })
        }
      }
    )
  }

  // 참여 횟수 소팅
  handleChangeCountSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyCount: R.merge(this.state.SortbyCount, {
          isAsc: !this.state.SortbyCount.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyCount.isAsc) {
          this.props.reqListUserDb({
            sort: 'try_count',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'try_count',
            order: 'DESC',
          })
        }
      }
    )
  }
  handleCountArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: false,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: false,
        }),
        SortbyCount: R.merge(this.state.SortbyCount, {
          isVisible: true,
        }),
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isVisible: false,
        }),
        SortbyScore: R.merge(this.state.SortbyScore, {
          isVisible: false,
        }),
      },
      () => {
        if (this.state.SortbyCount.isAsc) {
          this.props.reqListUserDb({
            sort: 'try_count',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'try_count',
            order: 'DESC',
          })
        }
      }
    )
  }

  // 공유 횟수 소팅

  handleChangeShareCountSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isAsc: !this.state.SortbyShareCount.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyShareCount.isAsc) {
          this.props.reqListUserDb({
            sort: 'share_count',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'share_count',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleShareScoreArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: false,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: false,
        }),
        SortbyCount: R.merge(this.state.SortbyCount, {
          isVisible: false,
        }),
        SortbyShareCount: R.merge(this.state.SortbyShareCount, {
          isVisible: true,
        }),
        SortbyScore: R.merge(this.state.SortbyScore, {
          isVisible: false,
        }),
      },
      () => {
        if (this.state.SortbyShareCount.isAsc) {
          this.props.reqListUserDb({
            sort: 'share_count',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'share_count',
            order: 'DESC',
          })
        }
      }
    )
  }

  /**
   * 퀴즈 결과 헤딩을 표시할 것인지
   * 1개의 데이터라도 reqform 결과가 아니라면 퀴즈 결과용 헤딩을 표시해야 한다.
   */
  get isQuizResultHeadingVisible() {
    return R.any(v => !v, this.props.isReqformResults)
  }

  render() {
    const { userDbList, userDbListData } = this.props
    let info1Show = false
    let info2Show = false
    let selectbox1Show = false
    let selectbox2Show = false
    let surveyAnswerShow = false
    let regionShow = false

    // 설문결과 테이블 헤딩을 표시할 것인지 결정한다.
    for (const row of this.props.userDbListData) {
      if (row.info1 !== null && R.trim(row.info1) !== '') info1Show = true
      if (row.info2 !== null && R.trim(row.info2) !== '') info2Show = true
      if (row.selectbox1 !== null && R.trim(row.selectbox1) !== '')
        selectbox1Show = true
      if (row.selectbox2 !== null && R.trim(row.selectbox2) !== '')
        selectbox2Show = true
      if (row.survey_answer !== null && R.trim(row.survey_answer) !== '')
        surveyAnswerShow = true

      if (row.r1 || row.r2 || row.r3) {
        regionShow = true
      }
    }

    return (
      <div>
        {userDbList.isLoading && <CubeSpinner />}

        <TableHeader
          renderTableInfo={() => (
            <div
              style={{
                height: '28px',
                verticalAlign: 'top',
                lineHeight: '28px',
              }}
            >
              <b>{addCommaToNum(userDbList.total)}</b>건의 결과가 있습니다.
            </div>
          )}
          xlsDownComponent={() => {
            return (
              <img
                style={{ cursor: 'pointer' }}
                src={'/images/btn-exel-download.svg'}
                onClick={this.downloadXls}
              />
            )
          }}
          searchComponent={() => {
            return (
              <ContentsSelectWrap>
                <StyledSelect
                  placeholder={'콘텐츠 선택'}
                  value={this.props.userDbContentsId}
                  options={this.contentsSelectOptions}
                  clearable={true}
                  onValueClick={this.handleSelectContent}
                  onInputClear={this.handleClearContent}
                  noOptionsMessage={() => messages['no options']}
                />
              </ContentsSelectWrap>
            )
          }}
        />

        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: '65px' }}>이름</th>
              <th style={{ minWidth: '150px' }}>휴대폰</th>
              <th style={{ minWidth: '190px' }}>이메일</th>
              {info1Show && <th style={{ minWidth: '100px' }}>정보1</th>}
              {info2Show && <th style={{ minWidth: '100px' }}>정보2</th>}
              {selectbox1Show && <th style={{ minWidth: '100px' }}>정보3</th>}
              {selectbox2Show && <th style={{ minWidth: '100px' }}>정보4</th>}
              {surveyAnswerShow && (
                <th style={{ minWidth: '100px' }}>설문번호</th>
              )}

              {/* 퀴즈 풀이 결과 영역. reqform 결과가 아닌 행이 있을 때 표시한다. */}
              {this.isQuizResultHeadingVisible && (
                <>
                  <TableTheadSort onClick={this.handleScoreArrow}>
                    점수&nbsp;{this.state.SortbyScore.isVisible ? (
                      <Sharp
                        src={
                          this.state.SortbyScore.isAsc === true
                            ? '/images/btn-page-up.svg'
                            : '/images/btn-page-down.svg'
                        }
                        alt="btn-page"
                        onClick={e => this.handleChangeScoreSort(e)}
                        visible={this.state.SortbyScore.isVisible}
                      />
                    ) : (
                      <Sharp src="/images/btn-page-up-down.svg" />
                    )}
                  </TableTheadSort>

                  {this.isQuizResultHeadingVisible && (
                    <TableTheadSort onClick={this.handleResttimeArrow}>
                      사용시간&nbsp;{this.state.SortbyRestTime.isVisible ? (
                        <Sharp
                          src={
                            this.state.SortbyRestTime.isAsc === true
                              ? '/images/btn-page-up.svg'
                              : '/images/btn-page-down.svg'
                          }
                          alt="btn-page"
                          onClick={e => this.handleChangeResttimeSort(e)}
                          visible={this.state.SortbyRestTime.isVisible}
                        />
                      ) : (
                        <Sharp src="/images/btn-page-up-down.svg" />
                      )}
                    </TableTheadSort>
                  )}

                  <TableTheadSort onClick={this.handleCountArrow}>
                    참여횟수&nbsp;{this.state.SortbyCount.isVisible ? (
                      <Sharp
                        src={
                          this.state.SortbyCount.isAsc === true
                            ? '/images/btn-page-up.svg'
                            : '/images/btn-page-down.svg'
                        }
                        alt="btn-page"
                        onClick={e => this.handleChangeCountSort(e)}
                        visible={this.state.SortbyCount.isVisible}
                      />
                    ) : (
                      <Sharp src="/images/btn-page-up-down.svg" />
                    )}
                  </TableTheadSort>

                  <TableTheadSort onClick={this.handleShareScoreArrow}>
                    공유점수&nbsp;{this.state.SortbyShareCount.isVisible ? (
                      <Sharp
                        src={
                          this.state.SortbyShareCount.isAsc === true
                            ? '/images/btn-page-up.svg'
                            : '/images/btn-page-down.svg'
                        }
                        alt="btn-page"
                        onClick={e => this.handleChangeShareCountSort(e)}
                        visible={this.state.SortbyShareCount.isVisible}
                      />
                    ) : (
                      <Sharp src="/images/btn-page-up-down.svg" />
                    )}
                  </TableTheadSort>
                </>
              )}

              {regionShow && <th style={{ minWidth: '150px' }}>지역</th>}

              <TableTheadSort onClick={this.handleAgreetimeArrow}>
                동의 시간&nbsp;{this.state.SortbyAgreeTime.isVisible ? (
                  <Sharp
                    src={
                      this.state.SortbyAgreeTime.isAsc === true
                        ? '/images/btn-page-up.svg'
                        : '/images/btn-page-down.svg'
                    }
                    alt="btn-page"
                    onClick={e => this.handleChangeAgreetimeSort(e)}
                    visible={this.state.SortbyAgreeTime.isVisible}
                  />
                ) : (
                  <Sharp src="/images/btn-page-up-down.svg" />
                )}
              </TableTheadSort>
              <th style={{ minWidth: '65px' }}>당첨</th>
              <th style={{ minWidth: '100px' }}>메모</th>
            </tr>
          </thead>
          <tbody
            className={classnames({
              grouped: this.state.isSortByPhone,
            })}
          >
            {userDbListData.map((userDb, index) => (
              <tr
                key={index}
                className={classnames({
                  'group-0': this.state.groupInfo[index] === 0,
                  'group-1': this.state.groupInfo[index] === 1,
                  'is-checked': this.state.userDbToCheckout.includes(
                    userDb.crid
                  ),
                })}
              >
                <td>{userDb.name}</td>
                <td>
                  {userDb.cell_phone}
                  {userDb.report_code === 1000 && (
                    <img
                      src="/images/icon-case-01.svg"
                      style={{
                        marginLeft: '9px',
                        marginRight: '-29px',
                        marginBottom: '-6px',
                      }}
                    />
                  )}
                </td>
                <td>{userDb.email}</td>
                {info1Show && <td>{userDb.info1}</td>}
                {info2Show && <td>{userDb.info2}</td>}
                {selectbox1Show && <td>{userDb.selectbox1}</td>}
                {selectbox2Show && <td>{userDb.selectbox2}</td>}
                {surveyAnswerShow && <td>{userDb.survey_answer}</td>}

                {/* 퀴즈 풀이 결과 영역. reqform 결과가 아닌 행이 있을 때 표시한다. */}
                {this.isQuizResultHeadingVisible && (
                  <>
                    <td>{userDb.ed}</td>
                    <td>{userDb.time}</td>
                    <td>{userDb.try_count}</td>
                    <td>{userDb.share_count}</td>
                  </>
                )}
                {/* 지역 */}

                {regionShow && (
                  <td style={{ minWidth: '150px' }}>
                    <ColRegion>
                      <span>{userDb.r1}</span>
                      <span>{userDb.r2}</span>
                      <span>{userDb.r3}</span>
                    </ColRegion>
                  </td>
                )}

                {/* 동의시간 */}
                <td>
                  {format(
                    userDb.agree_date,
                    DATE_FORMAT_DAY_AND_TIME
                  ).substring(5, 16)}
                </td>

                <td>
                  {userDb.random_selected && (
                    <span  >당첨</span>
                  )}
                </td>

                <td style={{ padding: '0px' }}>
                  <MemoSelectWrap>
                    <StyledSelect
                      value={userDb.memo}
                      placeholder={userDb.memo}
                      options={this.memoOptions}
                      onChange={value => {
                        this.handleChangeMemo(userDb, value)
                      }}
                    />

                    {/* 직접입력 선택한 row에 입력 폼 표시 */}
                    {this.state.memoInputOpenDb &&
                      this.state.memoInputOpenDb.id === userDb.id && (
                        <MemoInputForm onSubmit={this.handleSaveMemoText}>
                          <input
                            onChange={this.handleChangeMemoText}
                            value={this.state.memoInput}
                          />
                          <button type="submit">저장</button>
                        </MemoInputForm>
                      )}
                  </MemoSelectWrap>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <SubmitButtonWrap style={{ justifyContent: 'space-between' }}>
          <Paginator
            style={{ ...centeredXObj(), width: '400px', margin: '0' }}
            pageCount={userDbList.totalPage}
            forcePage={this.state.currentPage}
            onPageChange={({ selected }) =>
              this.handleChangePage({ page: selected })
            }
          />
        </SubmitButtonWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsListData: dotPath('contents.list.data', state),
  userDbList: dotPath('userDb.list', state),
  isReqformResults: isReqformResultSelector(state),
  userDbListData: dotPath('userDb.list.data', state),
  userDbContentsId: selectDbContentsId(state),
  dbOpenCount: dotPath('userDb.dbOpenCount', state),
  dbOpenCountEndDate: dotPath('userDb.dbOpenCountEndDate', state),
  dbPrice: dotPath('userDb.dbPrice', state),
  myStatus: state.mypage.myStatus,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListUserDb: userDbActions.reqListUserDb,
      reqDbOpenCount: userDbActions.reqDbOpenCount,
      reqOpenUserDb: userDbActions.reqOpenUserDb,
      reqListContents: contentsActions.reqListContents,
      showAlert: modalActions.showAlert,
      closeModal: modalActions.closeModal,
      reqDbPrice: userDbActions.reqDbPrice,
      showSpinner: spinnerActions.showSpinner,
      hideSpinner: spinnerActions.hideSpinner,
      showToast: toastActions.showToast,
      showErrorToast,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserDbList)
