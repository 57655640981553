import localStorageKeys from 'constant/localStorageKeys'

const canUseLocalStorage = typeof window !== undefined && !!window.localStorage

const localStorage = {
  /**
   * 키, 값, 유효기간(분). 유효기간 기본값은 3일
   */
  set: (key = '', value, expirationMin = 4320): void => {
    if (canUseLocalStorage) {
      const expirationMS = expirationMin * 60 * 1000
      const record = {
        value: JSON.stringify(value),
        timestamp: +new Date() + expirationMS,
      }

      window.localStorage.setItem(key, JSON.stringify(record))
    }
  },
  get: (key = ''): any => {
    if (canUseLocalStorage) {
      const record = JSON.parse(window.localStorage.getItem(key))

      if (!record) {
        return undefined
      }

      return +new Date() < record.timestamp && JSON.parse(record.value)
    }
  },
  remove: (key = '') => {
    if (canUseLocalStorage) {
      window.localStorage.removeItem(key)
    }
  },
}

export const clearLocalStorge = () => {
  Object.keys(localStorageKeys).forEach(key => {
    localStorage.remove(key)
  })
}

export default localStorage
