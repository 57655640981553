import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
// eslint-disable-next-line
import Button, { buttonTypes } from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { authActions } from 'store/auth/authActions'
import { modalActions } from 'store/modal/modalActions'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
// eslint-disable-next-line
import RouterLink from 'components/common/RouterLink'
import styled from 'styled-components'
import Footer from 'components/layout/Footer'
import PageWrap from 'components/layout/PageWrap'
import { devLog } from 'utils/log'

// eslint-disable-next-line
const RegisterSection = styled.section`
  margin: 55px auto 0;

  h2 {
    display: inline-flex;
    align-items: center;
    font-size: 22px;
    font-weight: normal;
    color: #374146;
  }

  ul {
    margin: 20px 27px 0;
    padding: 0;
    list-style: none;
  }

  li {
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    color: #9b9b9b;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`

/**
 * 로그인
 * 비밀번호 재설정을 위해 이메일 입력
 *
 * @class CheckEmail
 * @extends {React.Component}
 */
class CheckEmail extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      user_id: '',
      password: '',
    }
  }

  handleSubmitLogin = e => {
    e.preventDefault()
    const { user_id, password } = this.state
    devLog(`user_id, password`, user_id, password)

    this.props.reqLogin({
      user_id,
      password,
    })
  }

  render() {
    return (
      <div>
        <PageWrap>
          <RegisterContentBox>
            <PageTitle>로그인</PageTitle>
            <form onSubmit={this.handleSubmitLogin}>
              <RegisterInput
                value={this.state.user_id}
                style={{ marginTop: '20px' }}
                icon="ID"
                placeholder="아이디"
                onChange={e => this.setState({ user_id: e.target.value })}
              />
              <RegisterInput
                value={this.state.password}
                style={{ borderTop: 'none' }}
                type="password"
                icon="PASSWORD"
                placeholder="비밀번호"
                onChange={e => this.setState({ password: e.target.value })}
              />

              <Button
                style={{ marginTop: '20px', height: '54px' }}
                type="submit"
              >
                로그인
              </Button>
            </form>

            <a
              href="mailto:help@qbuz.io"
              style={{
                display: 'block',
                marginTop: '20px',
                fontSize: '14px',
                color: '#4a90e2',
                textDecoration: 'underline',
              }}
              to={'/login/check-email'}
            >
              비밀번호 분실시 help@qbuz.io 로 문의주세요
            </a>
          </RegisterContentBox>
        </PageWrap>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqLogin: authActions.reqLogin,
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckEmail)
