export type AuthorityItem = {
  authority: string,
}

export type User = {
  user_id: string,
  name: string,
  authorities: AuthorityItem[],
  token: string,
}

export const USER = {
  user_id: '',
  name: '',
  authorities: [],
  token: null,
}

export type LoginOption = {
  user_id: string,
  password: string,
  isAutoLogin: boolean,
  redirectTo: string, // 로그인 완료 후 이동
}

export type LeaveReason = {
  is_difficult: boolean,
  no_db: boolean,
  high_price: boolean,
  etc: boolean,
  reason: string,
}
