import { createActions } from 'redux-actions'
import * as R from 'ramda'
import { ReadListActionOption, ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import SampleContentsAPI from 'api/SampleContentsAPI'
import { makeAsyncType } from 'utils/redux'

export const types = {
  ...makeAsyncType('LIST_SAMPLE_CONTENTS'),
  ...makeAsyncType('READ_SAMPLE_CONTENTS'),
}

export const sampleContentsActions = createActions({
  [types.REQ_LIST_SAMPLE_CONTENTS]: (option: ReadListActionOption) => option,
  [types.REQ_LIST_SAMPLE_CONTENTS_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_SAMPLE_CONTENTS_FAIL]: undefined,

  [types.REQ_READ_SAMPLE_CONTENTS]: (id: string) => id,
  [types.REQ_READ_SAMPLE_CONTENTS_DONE]: d => d,
  [types.REQ_READ_SAMPLE_CONTENTS_FAIL]: undefined,
})

function* listSampleContents() {
  yield takeLatest(types.REQ_LIST_SAMPLE_CONTENTS, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => state.sampleContents.list.option),
        payload
      )
      const data = yield call(SampleContentsAPI.readList, option)

      yield put(sampleContentsActions.reqListSampleContentsDone(data))
    } catch (e) {
      console.error(e)
      yield put(sampleContentsActions.reqListSampleContentsFail(e))
    }
  })
}

function* readSampleContents() {
  yield takeLatest(types.REQ_READ_SAMPLE_CONTENTS, function*({ payload }) {
    try {
      const data = yield call(SampleContentsAPI.readItem, payload)
      yield put(sampleContentsActions.reqReadSampleContentsDone(data))
    } catch (e) {
      console.error(e)
      yield put(sampleContentsActions.reqReadSampleContentsFail(e))
    }
  })
}

export function* sampleContentsSaga(): Generator<any, any, any> {
  yield all([listSampleContents(), readSampleContents()])
}
