import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Modal from 'components/modal/Modal'
import { TermsModalBody, TermsModalContents } from './CompanyIntroModal'
import styled from 'styled-components'
import { termsActions } from 'store/terms/termsActions'

const Contents = styled(TermsModalContents)`
  h1 {
    margin-top: 0;
    margin-bottom: 52px;
  }

  h2 {
    font-size: 14px;
    margin-top: 35px;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 14px;
    margin: 35px 0 1rem;
  }

  h4 {
    font-size: 14px;
    margin: 35px 0 1rem;
  }

  ul {
    margin: 14px 0;
    list-style: none;
    padding-left: 14px;
  }
`

class PrivacyPolicyModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      terms: ``,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.reqReadPrivacyPolicy()
    }
  }

  render() {
    const { isOpen, onClose, ...rest } = this.props

    return (
      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <TermsModalBody>
          <Contents dangerouslySetInnerHTML={{ __html: this.props.terms }} />
        </TermsModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  terms: state.terms.privacyPolicy,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadPrivacyPolicy: termsActions.reqReadPrivacyPolicy,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PrivacyPolicyModal)
