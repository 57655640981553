import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'ramda'
import CubeSpinner from './CubeSpinner'

class LoadingSpinner extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <CubeSpinner isVisible={this.props.isVisible} />
  }
}

const mapStateToProps = state => ({
  isVisible: state.spinner.isVisible,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoadingSpinner)
