import React from 'react'
import styled from 'styled-components'
import { mixin } from 'styles'
import { isFunction } from 'utils/function'

export const TableWrap = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  tr {
    border-top: 1px solid #dee0e4;

    &:last-child {
      border-bottom: 1px solid #dee0e4;
    }
  }

  th {
    color: #374146;
    font-weight: normal;
  }

  td,
  th {
    padding: 10px;
    border: none;

    &.ellipsis {
      ${mixin.ellipsis()};
    }
  }

  tbody {
    /* 그룹별로 같은 색깔로 표시할 때 사용 */
    &.grouped tr {
      &.group-0 {
        background-color: #f4f4f4;
      }
      &.group-1 {
        background-color: #fff;
      }
    }

    tr {
      &:nth-child(2n- 1) {
        background-color: #f4f4f4;
      }
      &:nth-child(2n) {
        background-color: #fff;
      }

      &.is-checked {
        background-color: #cdcdcd !important; /* 그룹 스타일에 우선한다 */
      }
    }
  }
`

export default ({ children }) => {
  return <TableWrap>{children}</TableWrap>
}

const TableInfoWrap = styled.div`
  min-height: 66px;
  display: flex;
  align-items: center;
`

const TableSearchArea = styled.div`
  margin-left: auto;
`
const TableHeaderResult = styled.div`
  && {
    font-size: 16px;
  }
`
const XlsDownload = styled.div`
  && {
    margin-top: 5px;
  }
`

/**
 * 테이블 상단.
 * 테이블 정보, 검색 영역
 */
export const TableHeader = ({
  renderTableInfo,
  xlsDownComponent,
  searchComponent,
}) => {
  return (
    <TableInfoWrap>
      {isFunction(renderTableInfo) && (
        <TableHeaderResult>{renderTableInfo()}</TableHeaderResult>
      )}
      {isFunction(xlsDownComponent) && (
        <XlsDownload>{xlsDownComponent()}</XlsDownload>
      )}
      {isFunction(searchComponent) && (
        <TableSearchArea>{searchComponent()}</TableSearchArea>
      )}
    </TableInfoWrap>
  )
}
