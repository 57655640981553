import iconBold from './icon-bold.svg'
import iconUnorderedList from './icon-unordered-list.svg'
import iconImage from './icon-image.svg'
import iconLink from './icon-line@2x.png'
import { createGlobalStyle } from 'styled-components'

const actionbarColor = '#FFF !important;'
const borderColor = 'rgba(10, 10, 10, 0.1) !important;'
const borderStyle = 'solid !important;'
const borderWidth = '1px !important;'
const buttonSelectedColor = '#F0F0F0 !important;'
const contentHeight = '300px !important;'
const contentPadding = '10px !important;'

// https://github.com/jaredreich/pell#custom-styles
export default createGlobalStyle`
  .pell {
    width: 100%;
    box-sizing: border-box;
  }

  .pell-content {
    margin-top: 12px;
    box-sizing: border-box;
    height: ${contentHeight};
    outline: 0;
    overflow-y: auto;
    padding: ${contentPadding};
    border: solid 1px #dee0e4;
  }

  .pell-actionbar {
    background-color: ${actionbarColor};
    border-bottom: ${borderWidth} ${borderStyle} ${borderColor};
  }

  .pell-button {
    width: 46px;
    height: 40px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    outline: 0;
    background-size: contain;
    margin-right: 8px;

    &[title='Bold'] {
      background-image: url(${iconBold});
      color: transparent;
    }
    &[title='Image'] {
      background-image: url(${iconImage});
      color: transparent;
    }

    &[title='Unordered List'] {
      background-image: url(${iconUnorderedList});
      color: transparent;
    }

    &[title='Link'] {
      background-image: url(${iconLink});
      color: transparent;
    }
    
  }

  .pell-button-selected {
    background-color: ${buttonSelectedColor};
  }
`
