import * as R from 'ramda'
import { types } from './termsActions'
import { handleActions } from 'redux-actions'

const STATE = {
  companyIntro: '', // 회사소개
  termsOfService: '', // 약관
  privacyPolicy: '', // 개인정보취급방침
}

const reducer = handleActions(
  {
    [types.REQ_READ_COMPANY_INTRO_DONE]: (state, { payload }) => {
      return R.merge(state, {
        companyIntro: payload,
      })
    },
    [types.REQ_READ_TERMS_OF_SERVICE_DONE]: (state, { payload }) => {
      return R.merge(state, {
        termsOfService: payload,
      })
    },
    [types.REQ_READ_PRIVACY_POLICY_DONE]: (state, { payload }) => {
      return R.merge(state, {
        privacyPolicy: payload,
      })
    },
  },
  STATE
)
export default reducer
