import * as R from 'ramda'
import axiosClient from 'utils/axiosClient'
import {
  getListTotal,
  convertListOptionToCall,
  ListCallData,
  getTotalPage,
} from 'store/commonState/list'

import {
  Quiz,
  QuizReqBody,
  QuizList,
  QuizListReqOption,
} from 'models/quizModel'

export default class QuizAPI {
  static readList = async (option: QuizListReqOption): ListCallData => {
    const res: { data: QuizList } = await axiosClient().get(`/api/quizes`, {
      params: R.merge(convertListOptionToCall(option), {
        eid: option.eid,
      }),
    })

    const total = getListTotal(res)
    const totalPage = getTotalPage(total, option.size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }

  static readItem = async (qid = ''): Quiz => {
    const { data } = await axiosClient().get(`/api/quizes/${qid}`)
    return data
  }

  static createItem = async (reqBody: QuizReqBody): Quiz => {
    const { data } = await axiosClient().post(`/api/quizes`, reqBody)
    return data
  }

  static updateItem = async (qid, reqBody: QuizReqBody) => {
    return axiosClient().put(`/api/quizes/${qid}`, reqBody)
  }

  static deleteItem = async (qid: string = '') => {
    return axiosClient().delete(`/api/quizes/${qid}`)
  }

  static sortItem = async (
    qid: number,
    reqBody: {
      eid: number,
      sort_order: number,
    }
  ) => {
    return axiosClient().put(`/api/qsort/${qid}`, reqBody)
  }
}
