import React, { Component } from 'react'
import { Route, Switch /* , Redirect */ } from 'react-router-dom'
import DefaultLayout from 'components/layout/DefaultLayout'
import UserDbList from './UserDbList'

class UserDbRouteIndex extends Component {
  static propTypes = {}

  routes = [
    {
      path: '/',
      component: UserDbList,
    },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <DefaultLayout>
        <Switch>
          {this.routes.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={route.render}
              component={route.component}
            />
          ))}
        </Switch>
      </DefaultLayout>
    )
  }
}

export default UserDbRouteIndex
