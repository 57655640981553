import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'ramda'
import Alert from 'components/modal/Alert'
import Button, { buttonTypes } from 'components/common/Button'
import styled from 'styled-components'
import { mixin } from 'styles'
import styledMap from 'styled-map'
import colors from 'styles/color'
import { addCommaToNum } from 'utils/string'
import { mypageActions } from 'store/mypage/mypageActions'
import { devLog } from 'utils/log'
import axiosClient from 'utils/axiosClient'
import qs from 'query-string'
import { modalActions } from 'store/modal/modalActions'

type Props = {
  isOpen: boolean,
  onClose: Function,
}

const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const OptionButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mixin.square('120px')};
  background-color: ${styledMap`
    isSelected: ${colors.blue};
    !isSelected: #fff;
  `};
  color: ${styledMap`
    isSelected: #fff;
    !isSelected: ${colors.black};
  `};
  border-width: 1px;
  border-style: solid;
  border-color: ${styledMap`
    isSelected: ${colors.blue};
    !isSelected: #dde0e4;
  `};

  & + & {
    margin-left: 15px;
  }
`

class ChargePointModal extends Component<Props, null> {
  constructor(props) {
    super(props)
    this.state = {
      pointToCharge: this.pointOptions[0],
      selected: this.pointOptions.length - 1,
    }
  }

  pointOptions = [10000, 20000, 30000]

  get pointToCharge() {
    return this.pointOptions[this.state.selected]
  }

  componentDidMount() {
    this.props.reqReadMyStatus()
  }

  // 충전 후 포인트
  get pointAfterCharge() {
    return this.props.currentPoint + this.pointOptions[this.state.selected]
  }

  handleChargePoint = async () => {
    this.props.onClose()

    const { myStatus } = this.props
    try {
      const IMP = window.IMP // 생략해도 괜찮습니다.
      IMP.init('imp60304586') // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
      IMP.request_pay(
        {
          pg: 'inicis', // version 1.1.0부터 지원.
          pay_method: 'card,trans',
          merchant_uid: 'merchant_' + new Date().getTime(),
          name: '리드메이커 포인트',
          amount: this.pointToCharge, //
          custom_data: myStatus.user_id,
          buyer_email: myStatus.user_id,
          buyer_name: myStatus.name,
          buyer_tel: myStatus.phone,
          buyer_addr: '',
          buyer_postcode: '',
          m_redirect_url: 'https://manager.leadma.kr/my/payment/complete',
        },
        rsp => {
          if (rsp.success) {
            // 이용기간 증가
            axiosClient()
              .put(`/api/point`, rsp)
              .then(() => {
                this.props.history.push(
                  `/my/checkout-point-done?${qs.stringify(rsp)}`
                )
              })
          } else {
            devLog('rsp', rsp)
            this.props.showAlert({
              content: rsp.error_msg || '결제에 실패했습니다.',
            })
            this.setState({ isChargePointModalOpen: false })
          }
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    return (
      <Alert isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <h2
          style={{
            fontWeight: 'normal',
            fontSize: '22px',
            marginBottom: '17px',
          }}
        >
          포인트 충전
        </h2>

        <OptionsContainer>
          {this.pointOptions.map((point, index) => {
            return (
              <OptionButton
                key={index}
                isSelected={this.state.selected === index}
                onClick={() => this.setState({ selected: index })}
              >
                <div>
                  <b>{addCommaToNum(this.pointOptions[index])}</b>
                </div>

                <div style={{ marginTop: '5px' }}>포인트</div>
              </OptionButton>
            )
          })}
        </OptionsContainer>

        <p
          style={{
            marginTop: '17px',
            marginBottom: '17px',
          }}
        >
          <b>충전후 보유포인트 : {addCommaToNum(this.pointAfterCharge)} P</b>
        </p>

        <div style={{ display: 'flex' }}>
          <Button
            buttonType={buttonTypes.blue}
            style={{ marginRight: '10px' }}
            onClick={this.handleChargePoint}
          >
            결제하기
          </Button>
          <Button
            buttonType={buttonTypes.black}
            style={{}}
            onClick={this.props.onClose}
          >
            취소
          </Button>
        </div>
      </Alert>
    )
  }
}

const mapStateToProps = state => ({
  currentPoint: state.mypage.myStatus.point,
  myStatus: state.mypage.myStatus,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      reqReadMyStatus: mypageActions.reqReadMyStatus,
      reqChargePoint: mypageActions.reqChargePoint,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChargePointModal)
