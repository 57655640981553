import { createActions } from 'redux-actions'
import { ReadListActionOption, ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import PaymentAPI from 'api/PaymentAPI'
import { makeAsyncType } from 'utils/redux'
import * as R from 'ramda'
import { dotPath } from 'utils/ramda'

export const types = {
  ...makeAsyncType('LIST_PAYMENT'),
}

export const paymentActions = createActions({
  [types.REQ_LIST_PAYMENT]: (option: ReadListActionOption) => option,
  [types.REQ_LIST_PAYMENT_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_PAYMENT_FAIL]: undefined,
})

function* listPayment() {
  yield takeLatest(types.REQ_LIST_PAYMENT, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => dotPath('mypage.payment.list.option', state)),
        payload
      )
      const data = yield call(PaymentAPI.readList, option)

      yield put(paymentActions.reqListPaymentDone(data))
    } catch (e) {
      console.error(e)
      yield put(paymentActions.reqListPaymentFail(e))
    }
  })
}

export function* paymentSaga() {
  yield all([listPayment()])
}
