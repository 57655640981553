import React from 'react'
import styled from 'styled-components'
import { I18n } from 'react-i18next'
import { mixin } from 'styles'
import Modal, { ModalContentWrap } from './Modal'
import { textLineBreak } from 'components/common/textLineBreak'
import ReactDOMServer from 'react-dom/server'

const MODAL_BODY_WIDTH = '500px'

const ModalBody = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-width: ${MODAL_BODY_WIDTH};
  max-width: 1024px;
  flex-direction: column;
  padding: 60px 55px;
  border: none;
  background-color: #fff;
  font-size: 16px;
`

export const AlertWrap = styled.div`
  ${mixin.centeredFixed()};
  width: 540px;
  background-color: #424242;
  z-index: 2000;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.3), 0 0 24px 0 rgba(0, 0, 0, 0.22);
`

export const AlertBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  padding: 0;
  /* margin-bottom: 85px; */
  /* min-height: 194px; */
`

export const AlertButtons = styled.div`
  width: 100%;
  text-align: center;
  bottom: 48px;
  margin-top: 32px;
  display: flex;

  button {
    flex: 1;
    display: inline-block;
    color: #ffffff;
    background-color: #1f5de6;
    padding: 15px;
    border: none;
    font-size: 16px;

    &:nth-child(2n) {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }
  }
`

const AlertBtns = ({ onConfirm, confirmText }) => (
  <I18n ns={['admin']}>
    {t => (
      <AlertButtons>
        <button onClick={onConfirm}>{confirmText || t('confirm')}</button>
      </AlertButtons>
    )}
  </I18n>
)

export const ConfirmBtns = ({
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
}) => (
  <I18n ns={['admin']}>
    {t => (
      <AlertButtons>
        <button type="submit" onClick={onConfirm}>
          {confirmText || t('confirm')}
        </button>
        <button
          type="button"
          onClick={onCancel}
          style={{ backgroundColor: '#2b2b2b', color: '#fff' }}
        >
          {cancelText || t('cancel')}
        </button>
      </AlertButtons>
    )}
  </I18n>
)

type Props = {
  isOpen: boolean,
  isConfirm: boolean, // alert인지 confirm인지
  onConfirm: Function, // 확인 버튼 콜
  onCancel: Function, // 취소 버튼 콜
  onRequestClose: Function, // 확인, 취소 누르지 않고 닫기
  contentStyle?: Object,
  i18nKey: string, // I18n 번역 키. 전달되면 content에 우선한다
  content: string, // modal body에 들어갈 내용. React component, HTML 시용 가능
  children: React.component, // children이 있으면 content 무시
  isButtonVisible: boolean, // 기본 버튼 표시 여부
  confirmText: string, // 확인 버튼 텍스트
  cancelText: string, // 취소 버튼 텍스트
  zIndex?: number,
}
class Alert extends React.Component<Props, State> {
  static defaultProps = {
    onConfirm: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  /**
   * i18nkey가 전달되었으면 번역하고, 아니면 content를 그대로 사용한다.
   *
   * @param {*} t translate function
   * @returns
   * @memberof Alert
   */
  getBodyText(t) {
    const { i18nKey, content } = this.props
    return ReactDOMServer.renderToString(
      textLineBreak(i18nKey ? t(i18nKey) : content)
    )
  }

  render() {
    const {
      isOpen,
      onCancel,
      onClose,
      isButtonVisible = true,
      content,
    } = this.props

    return (
      <I18n ns={['admin']}>
        {t => (
          <Modal
            isOpen={isOpen}
            contentLabel="Alert"
            onClose={onClose || onCancel}
            zIndex={this.props.zIndex}
          >
            <ModalContentWrap>
              <ModalBody>
                {this.props.children ? (
                  <AlertBody>{this.props.children}</AlertBody>
                ) : (
                  <React.Fragment>
                    {typeof content === 'function' ? ( // if react component
                      <AlertBody>{content()}</AlertBody>
                    ) : (
                      <AlertBody
                        dangerouslySetInnerHTML={{
                          __html: this.getBodyText(t),
                        }}
                      />
                    )}

                    {isButtonVisible &&
                      (!this.props.isConfirm ? (
                        <AlertBtns {...this.props} />
                      ) : (
                        <ConfirmBtns {...this.props} />
                      ))}
                  </React.Fragment>
                )}
              </ModalBody>
            </ModalContentWrap>
          </Modal>
        )}
      </I18n>
    )
  }
}

export default Alert
