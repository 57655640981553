import getByteSize from './getByteSize'
import { VALID_BYTE_SIZE_OF_USERNAME } from 'constant'

/**
 * check first parameter is function type
 * @param {*} fn
 */
export const isFunc = fn => typeof fn === 'function'

/**
 * 패스워드가 허용된 문자열만 포함하고 있는지
 * @param {*} pw
 */
export const isPasswordHasValidCharset = pw => {
  return /^([a-zA-Z]|[0-9]|[^\s])+$/.test(pw)
}

/**
 * 숫자+영문의 조합으로 8~15자리 문자열만 true
 * @param {*} pw
 */
export const isValidPasswd = (pw = '') => {
  const isValidLen = pw.length >= 8 && pw.length <= 15
  const isPasswdIncludesNum = /\d/.test(pw)
  const isPasswdIncludesStr = /[a-zA-Z]/.test(pw)

  return (
    isPasswordHasValidCharset(pw) &&
    isValidLen &&
    isPasswdIncludesNum &&
    isPasswdIncludesStr
  )
}

export const isMobileNum = (num = '') => {
  const trimmed = removeDash(num)
  return /^01[0,1,6,7,8,9]\d{3,4}\d{4}$/.test(trimmed)
}

export const removeDash = (str = '') => str.replace(/-/g, '')

export const isValidName = (name = '') => {
  const isNameValidSize = getByteSize(name) <= VALID_BYTE_SIZE_OF_USERNAME
  const isNameHasValidChar = !/[^가-힣a-zA-Z0-9]/.test(name)
  return isNameValidSize && isNameHasValidChar
}

export const isValidEmail = email => {
  const isValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  )
  const isEmptyAfterAtSign = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@$/.test(email)
  const isNoAtSign = email.search('@') < 0

  return {
    isValid,
    message: isEmptyAfterAtSign
      ? `'@' 뒷부분을 입력해주세요. '메일ID'(이)가 완전하지 않습니다.`
      : isNoAtSign
        ? `이메일주소에 '@'를 포함해주세요. '메일ID'에 '@'가 없습니다.`
        : '',
  }
}
