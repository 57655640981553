import React from 'react'
import ReactPaginate from 'react-paginate'
import './Paginator.css'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import classnames from 'classnames'

const Wrap = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`

type Props = {
  onPageChange: ({ selected: number }) => any,
  initialPage: number, // starts with 0
  pageCount: number,
  style: Object, // overites Wrap component style
}
type State = {}

/**
 * 페이지네이션 컴포넌트
 * https://github.com/AdeleD/react-paginate
 *
 * 페이지 번호는 쿼리스트링의 page 파라미터로 전달받는 것을 가정한 컴포넌트다.
 * withRouter로 페이지 번호를 가져온다.
 *
 */
class Paginate extends React.Component<Props, State> {
  static defaultProps = {}

  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Wrap style={{ ...this.props.style }}>
        <ReactPaginate
          {...this.props}
          // forcePage={search ? parseInt(search.page, 10) : null}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          containerClassName={'PaginateContainer'}
          pageClassName={'PaginatePage'}
          pageLinkClassName={'PaginatePageLink'}
          previousLinkClassName={classnames('PaginatePreviousLink', {
            'is-hidden': this.props.pageCount === 1,
          })}
          nextLinkClassName={classnames('PaginateNextLink', {
            'is-hidden': this.props.pageCount === 1,
          })}
          activeClassName={'PaginateActive'}
          breakClassName={'PaginateBreak'}
        />
      </Wrap>
    )
  }
}

export default withRouter(Paginate)
