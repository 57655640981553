export default {
  contentWidth: '1200px',
  contentHoriPad: '30px',
  headerHeight: '72px',
  footerHeight: '100px',
  sideBarWidth: '200px',
  appMaxWidth: '960px',
  mainHoriPadding: '20px',
  panelLeftHeadingWidth: '190px',
  panelInputWidth: '525px', // 패널 바디 내부 인풋 넓이.
}
