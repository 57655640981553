import * as centered from './centered'
import * as shape from './shape'
import { clearFix } from 'polished'

export default {
  ...centered,
  ...shape,
  clearFix,
  ellipsis: () => `
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
}
