import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from 'pages/login'
import NotFound from 'pages/NotFound'
import { userIsAuthed, userIsNotAuthed } from './authWrapper'
import UserDb from 'pages/userdb'

const AppRoutes = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/login" component={userIsNotAuthed(Login)} />
        <Route path="/userdb" component={userIsAuthed(UserDb)} />
        <Route path="/404" component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )
}

export default AppRoutes
