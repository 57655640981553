import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
import { dotPath } from 'utils/ramda'
import qs from 'query-string'
import { modalActions } from 'store/modal/modalActions'
import { isValidPasswd } from 'utils/validator'
import { registerActions } from 'store/mypage/registerActions'

/**
 * 회원가입
 * 비밀번호
 *
 * @class ResetPassword
 * @extends {React.Component}
 */
class ResetPassword extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      verify_code: '',
    }
  }

  componentDidMount() {
    const { email, code } = qs.parse(dotPath('props.location.search', this))

    // store에 저장된 password 등록
    this.setState({
      email,
      verify_code: code,
    })
  }

  /**
   * 새 비밀번호 설정
   *
   * @memberof ResetPassword
   */
  handleClickResetPassword = e => {
    e.preventDefault()

    if (!isValidPasswd(this.state.password)) {
      this.props.showAlert({
        i18nKey:
          '비밀번호는 숫자+영문의 조합으로 8~15자리의 비밀번호를 설정해주세요',
      })
    } else {
      const { email, password, verify_code } = this.state
      this.props.reqResetPassword({ email, password, verify_code })
    }
  }

  render() {
    return (
      <Centering>
        <RegisterContentBox>
          <PageTitle>새 비밀번호 설정을 시작합니다.</PageTitle>
          <p style={{ marginTop: '9px' }}>
            로그인시 사용할 비밀번호를 설정합니다.
          </p>
          <form onSubmit={this.handleClickResetPassword}>
            <RegisterInput
              type="password"
              icon="PASSWORD"
              placeholder="숫자+영문(8~15자리)로 입력하세요"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
            />
            <Button
              style={{
                marginTop: '26px',
              }}
              type="submit"
            >
              확인
            </Button>
          </form>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      showConfirm: modalActions.showConfirm,
      reqResetPassword: registerActions.reqResetPassword,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword)
