import * as R from 'ramda'
import { types } from './sampleQuizActions'
import { handleActions } from 'redux-actions'
import { Quiz } from 'models/quizModel'
import {
  LIST_STATE,
  ListState,
  emptyListWithError,
  startLoadingList,
  updateList,
} from 'store/commonState/list'

type State = {
  list: ListState<Quiz>,
}

const STATE: State = {
  list: R.mergeDeepRight(LIST_STATE, {
    option: {
      sort: 'sort_order',
    },
  }),
}

const reducer = handleActions(
  {
    [types.REQ_LIST_SAMPLE_QUIZ]: startLoadingList,
    [types.REQ_LIST_SAMPLE_QUIZ_DONE]: updateList,
    [types.REQ_LIST_SAMPLE_QUIZ_FAIL]: emptyListWithError,
  },
  STATE
)

export default reducer
