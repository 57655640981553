import { createActions } from 'redux-actions'
import { all, put, takeEvery, select } from 'redux-saga/effects'

export const types = {
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
}

export const spinnerActions: {
  showSpinner: Function,
  hideSpinner: Function,
} = createActions({
  [types.SHOW_SPINNER]: payload => payload,
  [types.HIDE_SPINNER]: payload => payload,
})

/**
 * 비동기 리퀘스트 액션 확인해서 로딩 스피너를
 */
function* reqMesseage() {
  yield takeEvery(action => /^REQ/.test(action.type), function*(action) {
    const { type } = action
    const isVisible = yield select(state => state.spinner.isVisible)

    if (/^REQ.+[^DONE|FAIL]$/.test(type) && !isVisible) {
      yield put(spinnerActions.showSpinner())
    } else if (/^REQ.+[DONE|FAIL]$/.test(type) && isVisible) {
      yield put(spinnerActions.hideSpinner())
    }
  })
}

export function* spinnerSaga() {
  yield all([reqMesseage()])
}
