import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
  margin-top: 23px;
  margin-bottom: 20px;
`

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  color: #383838;
  margin-bottom: 0;
`

const SectionDesc = styled.p`
  margin-top: 9px;
  font-size: 12px;
  color: #7d7d7d;
`

/**
 * 섹션 타이틀과 설명 영역
 */
export default ({ title = '', desc }) => {
  return (
    <Wrap>
      <SectionTitle>
        {typeof title === 'function' ? title() : title}
      </SectionTitle>
      {desc && <SectionDesc dangerouslySetInnerHTML={{ __html: desc }} />}
    </Wrap>
  )
}
