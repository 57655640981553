import React from 'react'
import * as R from 'ramda'

export const withCdn = (url = '') => {
  return `${process.env.REACT_APP_CDN_URL}/${url}`
}

export const cdnImage = (url = '') => {
  return withCdn(`frontend/manager/images/${url}`)
}

export function replaceNewlineToBr(str = '') {
  if (typeof str === 'string') {
    return str.replace(/(?:\r\n|\r|\n|\\r\\n|\\r|\\n)/g, '<br />')
  } else {
    return str
  }
}

export function addCommaToNum(x = '') {
  // 캡쳐링 그룹이 중첩되어 있다. 숫자 3개가 반복되는 패턴의 시작점을 찾아서 그 위치를 콤마로 바꾼다. \B는 단어의 경계가 아닌 곳에 일치하므로
  // 숫자1~2개 + \B + 숫자 3개 반복 + 숫자가 아닌 문자 패턴이 된다
  // 경계 \B를 콤마로 변경한다
  // ex) 1 + \B + 000 + ,000
  //     1,000,000
  return x === null ? '' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const isMobileNum = (num = '') => {
  const trimmed = removeDash(num)
  return /^01[0,1,6,7,8,9]\d{3,4}\d{4}$/.test(trimmed)
}

export const removeDash = (str = '') => str.replace(/-/g, '')

/**
 * input onChange에서 사용한다
 */
export function addHyphenToCellPhone(str = '') {
  const num = removeDash(str.toString())
  // return num.length <= 3 || num.length > 11
  //   ? num
  //   : num.length <= 7
  //     ? num.replace(/(\d{3})(\d{1,4})/, '$1-$2')
  //     : num.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3')
  // 02로 시작하는 전화번호 포맷 추가
  return num.length <= 3 || num.length > 11
    ? num
    : num.length <= 8
      ? num.replace(/(\d{4})(\d{4})/, '$1-$2')
      : num.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
}

/**
 * input onBlur에서 사용한다.
 * 10자리의 전화번호는 가운데 자리를 3개로 해서 변환한다.
 */
export const confirmPhoneFormat = (str = '') => {
  const num = removeDash(str.toString())
  return num.length === 10
    ? num.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3')
    : num.replace(/(\d{3})(\d{4})(\d+)/, '$1-$2-$3')
}

/**
 * 공백 문자인지 확인한다. 길이가 0이거나 모두 공백 문자이거나
 * @param {} str
 */
export const isEmptyString = str => {
  if (typeof str !== 'string') {
    return false
  } else {
    return str.replace(/\s/g, '') === ''
  }
}

/**
 * 문자열에 개행 문자가 있으면 JSX에서 줄바꿈이 되도록 처리한다.
 * https://codesandbox.io/s/w6jv6yjnv5
 * @param {sstring } text
 */
export const addNewLine = (text = '') => {
  if (typeof text === 'string') {
    const lines = text.toString().split(/\\r\\n|\r\n|\r|\\r|\n|\\n/g) || []

    return lines.map((partial, i) => (
      <span key={i}>
        {partial}
        {i !== lines.length - 1 ? <br /> : null}
      </span>
    ))
  } else {
    return text
  }
}

// 개행문자, 공백문자 제거
export const removeNewlineChar = (str = '') => {
  if (typeof str !== 'string') {
    return str
  } else {
    return str.replace(/\\n|\n|\\r|\r/g, '')
  }
}

// 조건부 클래스명
export const condClass = (predicate, className) => {
  if (typeof predicate === 'function') {
    return R.ifElse(predicate, R.always(className), R.always(''))
  } else {
    return R.ifElse(R.identical(true), R.always(className), R.always(''))(
      predicate
    )
  }
}

export const isHttpProtocol = url => {
  return /^https?:\/\/.+/.test(url)
}

export const stripProtocol = (url = '') => {
  return url.replace(/https?:\/\//, '')
}
