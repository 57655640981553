import * as React from 'react'
import styled from 'styled-components'
import { color, mixin } from 'styles'
import { cdnImage } from 'utils/string'

const CheckBoxGroup = styled.div`
  display: inline-flex;
  align-items: center;

  & > input {
    display: none;
  }

  & > input + label {
    display: inline-block;
    position: relative;
    padding-left: 20px;
    height: 20px;
    color: ${color.text};
    margin-bottom: 0;
    min-width: 5em;

    &:hover {
      cursor: pointer;
    }

    &:before {
      ${mixin.centeredY()};
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: ' ';
      background-image: url(${cdnImage('btn-check-off.svg')});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    & > span {
      margin-left: 4px;
    }
  }

  & > input:checked + label {
    &:before {
      background-image: url(${cdnImage('btn-check-on.svg')});
    }
  }
`

type Props = {
  id: string,
  onChange: Function,
  children: any,
  checked: boolean,
  disabled?: boolean,
  name?: string,
  labelStyle?: object,
}

class CheckBox extends React.Component<Props, any> {
  static defaultProps = {
    checked: false,
    disabled: false,
  }

  render() {
    const {
      onClick,
      children,
      id,
      onChange,
      checked,
      disabled,
      name,
    } = this.props

    return (
      <CheckBoxGroup onClick={onClick}>
        <input
          id={id}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          name={name}
        />
        <label
          htmlFor={id}
          style={{
            opacity: disabled ? 0.6 : 1,
          }}
        >
          {(children || name) && <span>{children || name}</span>}
        </label>
      </CheckBoxGroup>
    )
  }
}

export default CheckBox
