import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Modal from 'components/modal/Modal'
import { TermsModalBody, TermsModalContents } from './CompanyIntroModal'
import styled from 'styled-components'
import { termsActions } from 'store/terms/termsActions'

const Contents = styled(TermsModalContents)`
  h2 {
    font-size: 20px;
    /* margin-bottom: 35px; */
  }

  h3 {
    margin: 40px 0;
    font-size: 14px;
  }

  .gap {
    display: block;
    margin-top: 40px;
  }
`

class TermsOfServiceModal extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.reqReadTermsOfService()
    }
  }

  render() {
    const { isOpen, onClose, ...rest } = this.props

    return (
      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <TermsModalBody>
          <Contents dangerouslySetInnerHTML={{ __html: this.props.terms }} />
        </TermsModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  terms: state.terms.termsOfService,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadTermsOfService: termsActions.reqReadTermsOfService,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TermsOfServiceModal)
