import { createActions } from 'redux-actions'
import { ToastItem } from 'models/toastModel'
import { toastTypes } from 'models/toastModel'
import { all, put, takeEvery /* takeLatest */ } from 'redux-saga/effects'
import { delay } from 'redux-saga'

export const types = {
  SHOW_TOAST: 'SHOW_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  PUSH_TOAST: 'PUSH_TOAST', // 큐에 토스트 메시지 추가
  SHIFT_TOAST: 'SHIFT_TOAST', // 토스크 큐의 첫번째 아이템 제거
}

export const toastActions: {
  showToast: Function,
  pushToast: Function,
  shiftToast: Function,
} = createActions({
  [types.SHOW_TOAST]: (p: ToastItem) => p,
  [types.PUSH_TOAST]: (p: ToastItem) => p,
  [types.SHIFT_TOAST]: undefined,
})

export const showErrorToast = ({ content } = {}) => ({
  type: types.SHOW_TOAST,
  payload: {
    type: toastTypes.DANGER,
    content,
  },
})

function* toastProcess() {
  // 토스트 메시지는 3초 후에 큐에서 제거하도록 한다.
  yield takeEvery(types.SHOW_TOAST, function*({ payload }) {
    yield put(toastActions.pushToast(payload))
    yield delay(2000)
    yield put(toastActions.shiftToast())
  })
}

export function* toastSaga(): Generator<any, any, any> {
  yield all([toastProcess() /* reqMesseage() */])
}
