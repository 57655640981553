import styled from 'styled-components'
import { cdnImage } from 'utils/string'

/**
 * 삭제 버튼을 포함한 박스
 */
export const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 160px;

  & > img {
    display: block;
    width: 100%;
    min-height: 100px;

    &::after {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      width: 100%;
      height: 100%;
      content: 'image not found';
      font-family: monospace;
      background-color: #f7f7f7;
    }
  }

  &:hover {
    & > button {
      display: block;
    }
  }
`

export const ImageDeleteButton = styled.button`
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  display: none;
  width: 40px;
  height: 40px;
  background: url(${cdnImage('btn-delete-image.svg')}) no-repeat center;
  background-size: contain;
  background-color: transparent;
  border: none;
  color: #ffffff;
`

// export const ImageDeleteButton = styled.button`
//   ${mixin.centered()};
//   width: 100px;
//   height: 40px;
//   background-color: #374146;
//   color: #fff;
//   font-size: 14px;
// `
