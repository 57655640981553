import React from 'react'
import styled from 'styled-components'
import { ToastItem, toastTypes } from 'models/toastModel'
import { I18n } from 'react-i18next'

const Wrap = styled.div`
  width: 240px;
  padding: 0.7rem 1rem;
  font-size: 1.4rem;
  letter-spacing: -1px;
  color: #fefefe;
  font-weight: normal;
  background-color: ${({ type }) => {
    switch (type) {
      case toastTypes.DANGER:
        return '#ea3839'

      case toastTypes.WARNING:
        return '#ff9e03'

      default:
        return '#4B5259'
    }
  }};
  border-radius: 4px;
  margin-bottom: 1rem;
  box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.3);
`

const Content = styled.div`
  font-size: 0.9em;
`

type Props = {
  toast: ToastItem,
}

function ToastMessage(props: Props) {
  const { type, content, i18nKey } = props.toast

  return (
    <I18n ns={['admin']}>
      {t => (
        <Wrap type={type}>
          <Content
            dangerouslySetInnerHTML={{ __html: i18nKey ? t(i18nKey) : content }}
          />
        </Wrap>
      )}
    </I18n>
  )
}

export default ToastMessage
