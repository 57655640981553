import * as R from 'ramda'
import { createActions } from 'redux-actions'
import { ReadListActionOption, ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import ContentsAPI from 'api/ContentsAPI'
import { push, routerActions } from 'connected-react-router'
import { makeAsyncType } from 'utils/redux'
import FileAPI from 'api/FileAPI'
import { InsuranceType, ContentsReqBody } from 'models/contentsModel'
import { modalActions } from 'store/modal/modalActions'
import { isHttpProtocol } from 'utils/string'
import isTruthy, { isFalsey } from 'utils/isTruthy'
import {
  CONTENTS_MAIN_IMAGE_WIDTH,
  CONTENTS_MAIN_IMAGE_HEIGHT,
  BUSINESS_CARD_IMAGE_WIDTH,
  BUSINESS_CARD_IMAGE_HEIGHT,
} from 'constant'
import sanitizeHtml from 'sanitize-html'
import { delay } from 'redux-saga'
import { isFunction } from 'utils/function'

export const types = {
  ...makeAsyncType('LIST_CONTENTS'), // 컨텐츠 목록
  ...makeAsyncType('READ_CONTENTS'), // 상세
  SET_CONTENTS_REQ_BODY: 'SET_CONTENTS_REQ_BODY', // 폼 데이터 업데이트
  SET_CONTENTS_REQ_BODY_DEBOUNCED: 'SET_CONTENTS_REQ_BODY_DEBOUNCED', // 폼 데이터 업데이트
  ...makeAsyncType('CREATE_CONTENTS'), // 생성
  ...makeAsyncType('UPDATE_CONTENTS'), // 수정
  ...makeAsyncType('DELETE_CONTENTS'), // 삭제
  ...makeAsyncType('DUPLICATE_CONTENTS'), // 컨텐츠 복제
  ...makeAsyncType('UPLOAD_CONSULT_IMAGE'), // 상담 이미지 업로드
  ...makeAsyncType('LIST_INSURANCE_TYPES'), // 보험 타입 조회
  ...makeAsyncType('UPDATE_INSURANCE_TYPES'), // 보험 타입 수정
  ...makeAsyncType('UPDATE_APPLY_VIEW'), // 결과화면 수정
  ...makeAsyncType('READ_MAX_CONTENTS'), // 최대 컨텐츠 수
}

export const contentsActions = createActions({
  [types.REQ_LIST_CONTENTS]: (payload: ReadListActionOption) => payload,
  [types.REQ_LIST_CONTENTS_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_CONTENTS_FAIL]: undefined,

  [types.REQ_READ_CONTENTS]: (id: string) => id,
  [types.REQ_READ_CONTENTS_DONE]: (d: Event) => d,
  [types.REQ_READ_CONTENTS_FAIL]: undefined,

  [types.SET_CONTENTS_REQ_BODY]: (reqBody: ContentsReqBody) => reqBody,
  [types.SET_CONTENTS_REQ_BODY_DEBOUNCED]: (reqBody: ContentsReqBody) =>
    reqBody,

  [types.SET_FILE_TO_UPLOAD]: (fileToUpload: {
    event_image?: string,
    card_photo?: string,
  }) => fileToUpload,

  [types.REQ_CREATE_CONTENTS]: (payload: {
    reqBody: ContentsReqBody,
    fileToUpload: any,
  }) => payload,
  [types.REQ_CREATE_CONTENTS_DONE]: (d: Event) => d,
  [types.REQ_CREATE_CONTENTS_FAIL]: undefined,

  [types.REQ_UPDATE_CONTENTS]: (payload: {
    reqBody: ContentsReqBody,
    fileToUpload: any,
    contentsId: number,
    isRedirectToQuiz: boolean, // 업데이트 후 퀴즈 수정으로 이동할 것인지.
  }) => payload,
  [types.REQ_UPDATE_CONTENTS_DONE]: undefined,
  [types.REQ_UPDATE_CONTENTS_FAIL]: undefined,

  [types.REQ_DELETE_CONTENTS]: (id: string) => id,
  [types.REQ_DELETE_CONTENTS_DONE]: undefined,
  [types.REQ_DELETE_CONTENTS_FAIL]: undefined,

  [types.REQ_DUPLICATE_CONTENTS]: (id: string) => id,
  [types.REQ_DUPLICATE_CONTENTS_DONE]: undefined,
  [types.REQ_DUPLICATE_CONTENTS_FAIL]: undefined,

  [types.REQ_UPLOAD_CONSULT_IMAGE]: (payload: {
    file: File,
    contentsId: string,
  }) => payload,
  [types.REQ_UPLOAD_CONSULT_IMAGE_DONE]: url => url,
  [types.REQ_UPLOAD_CONSULT_IMAGE_FAIL]: undefined,

  [types.REQ_LIST_INSURANCE_TYPES]: (payload: { contentsId: string }) =>
    payload,
  [types.REQ_LIST_INSURANCE_TYPES_DONE]: (payload: {
    insuranceTypes: InsuranceType[],
  }) => payload,
  [types.REQ_LIST_INSURANCE_TYPES_FAIL]: undefined,

  // [types.REQ_UPDATE_INSURANCE_TYPES]: (payload: {
  //   contentsId: string,
  //   insuranceTypes: InsuranceType[],
  // }) => payload,
  // [types.REQ_UPDATE_INSURANCE_TYPES_DONE]: undefined,
  // [types.REQ_UPDATE_INSURANCE_TYPES_FAIL]: undefined,

  [types.REQ_UPDATE_APPLY_VIEW]: (payload: {
    contentsId: string,
    insuranceTypes: InsuranceType[],
    consultImageFile: File,
  }) => payload,
  [types.REQ_UPDATE_APPLY_VIEW_DONE]: undefined,
  [types.REQ_UPDATE_APPLY_VIEW_FAIL]: undefined,

  [types.REQ_READ_MAX_CONTENTS]: undefined,
  [types.REQ_READ_MAX_CONTENTS_DONE]: undefined,
  [types.REQ_READ_MAX_CONTENTS_FAIL]: undefined,
})

function* listContents() {
  yield takeLatest(types.REQ_LIST_CONTENTS, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(rootState => rootState.contents.list.option),
        payload
      )
      const data = yield call(ContentsAPI.readList, option)

      yield put(contentsActions.reqListContentsDone(data))

      // 콜백 함수가 있으면 실행한다
      if (isFunction(payload.onReqSuccess)) {
        yield payload.onReqSuccess(data)
      }
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqListContentsFail(e))
    }
  })
}

function* readContents() {
  // history.push('/')
  yield takeLatest(types.REQ_READ_CONTENTS, function*({ payload }) {
    try {
      const data = yield call(ContentsAPI.readItem, payload)
      yield put(contentsActions.reqReadContentsDone(data))
      yield put(contentsActions.setContentsReqBody(data))
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqReadContentsFail(e))
    }
  })
}

const isValidUrl = (reqBody: ContentsReqBody = {}) => {
  return R.allPass([
    contents =>
      isTruthy(contents.blog_url) ? isHttpProtocol(contents.blog_url) : true,
    contents =>
      isTruthy(contents.facebook_url)
        ? isHttpProtocol(contents.facebook_url)
        : true,
    contents =>
      isTruthy(contents.instagram_url)
        ? isHttpProtocol(contents.instagram_url)
        : true,
    contents =>
      isTruthy(contents.openchat_url)
        ? isHttpProtocol(contents.openchat_url)
        : true,
  ])(reqBody)
}

const isValidResultLink = url => {
  return isTruthy(url) ? isHttpProtocol(url) : true
}

/**
 * 데이터 검증에 실패하면 promise reject 시켜서 catch 블럭으로 이동되도록 한다.
 */
async function validateContentsForm(reqBody: ContentsReqBody) {
  if (!isValidUrl(reqBody)) {
    throw new Error('SNS 주소는 http 또는 https로 시작해야 합니다.')
  }

  if (!isValidResultLink(reqBody.result_url)) {
    throw new Error('링크 URL은 http 또는 https로 시작해야 합니다.')
  }

  if (isFalsey(reqBody.title)) {
    throw new Error('제목을 입력하세요')
  }

  const isEventbodyEmtpy = isFalsey(
    // 태그를 모두 제거한 마크업이 비었는지 확인
    sanitizeHtml(reqBody.eventbody, {
      allowedTags: [],
    })
  )
  if (isEventbodyEmtpy) {
    throw new Error('본문을 입력하세요')
  }
}

/**
 * 파일을 업로드하고 URL을 리퀘스트 객체에 업데이트한다.
 */
export const mergeContentsImageUrl = async ({
  contentsReqBody,
  fileToUpload = {},
}): ContentsReqBody => {
  try {
    let reqBody = R.clone(contentsReqBody)

    if (fileToUpload.event_image) {
      const url = await FileAPI.upload({
        file: fileToUpload.event_image,
        resize: true,
        width: CONTENTS_MAIN_IMAGE_WIDTH * 2,
        height: CONTENTS_MAIN_IMAGE_HEIGHT * 2,
      })
      reqBody = R.merge(reqBody, { event_image: url })
    }

    if (fileToUpload.card_photo) {
      const url = await FileAPI.upload({
        file: fileToUpload.card_photo,
        resize: true,
        width: BUSINESS_CARD_IMAGE_WIDTH * 2,
        height: BUSINESS_CARD_IMAGE_HEIGHT * 2,
      })
      reqBody = R.merge(reqBody, { card_photo: url })
    }

    return reqBody
  } catch (e) {
    console.error(e)
  }
}

function* createContents() {
  yield takeLatest(types.REQ_CREATE_CONTENTS, function*({ payload }) {
    try {
      const { reqBody, fileToUpload } = payload
      const history = yield select(state => state.history)

      yield call(validateContentsForm, reqBody)

      // 이미지 업로드 후 주소 병합
      const reqBodyWithFile = yield call(mergeContentsImageUrl, {
        contentsReqBody: reqBody,
        fileToUpload,
      })

      // API 호출
      const data = yield call(ContentsAPI.createItem, reqBodyWithFile)

      yield put(contentsActions.reqCreateContentsDone(data))

      yield put(routerActions.push(`/contents/edit/${data.eid}`))
      yield put(
        modalActions.showConfirm({
          i18nKey: '저장되었습니다.\n퀴즈문항을 편집하겠습니까?',
          confirmText: '예',
          cancelText: '아니오',
          onConfirm: () => {
            history.push(`/contents/edit/${data.eid}/quiz/list`)
          },
        })
      )
    } catch (e) {
      console.error(e)
      console.log(`e`, JSON.stringify(e))

      yield put(
        modalActions.showAlert({
          content: e.message || '컨텐츠 생성에 실패했습니다.',
        })
      )
      yield put(contentsActions.reqCreateContentsFail(e))
    }
  })
}

function* updateContents() {
  yield takeLatest(types.REQ_UPDATE_CONTENTS, function*({ payload }) {
    const {
      reqBody,
      contentsId,
      fileToUpload,
      isRedirectToQuiz = false,
    } = payload

    try {
      yield call(validateContentsForm, reqBody)

      // 이미지가 업로드 후 주소 병합
      const reqBodyWithFile = yield call(mergeContentsImageUrl, {
        contentsReqBody: reqBody,
        fileToUpload,
      })

      yield call(ContentsAPI.updateItem, {
        reqBody: reqBodyWithFile,
        id: contentsId,
      })
      yield put(contentsActions.reqUpdateContentsDone())

      const history = yield select(state => state.history)

      if (isRedirectToQuiz) {
        yield put(
          modalActions.showConfirm({
            i18nKey: '저장되었습니다.\n퀴즈문항을 편집하겠습니까?',
            confirmText: '예',
            cancelText: '아니오',
            onConfirm: () => {
              history.push(`/contents/edit/${contentsId}/quiz/list`)
            },
          })
        )
      } else {
        yield put(
          modalActions.showAlert({
            i18nKey: '저장되었습니다',
          })
        )
      }

      yield put(contentsActions.reqReadContents(contentsId)) // 업데이트한 상세정보 가져옴
    } catch (e) {
      console.error(e)
      yield put(
        modalActions.showAlert({
          content: e.message || '컨텐츠 수정에 실패했습니다.',
        })
      )
      yield put(contentsActions.reqUpdateContentsFail(e))
    }
  })
}

function* deleteContents() {
  yield takeLatest(types.REQ_DELETE_CONTENTS, function*({ payload }) {
    try {
      yield call(ContentsAPI.deleteItem, payload)
      yield put(contentsActions.reqDeleteContentsDone())
      yield put(push('/contents'))
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqDeleteContentsFail(e))
    }
  })
}

function* duplicateContents() {
  yield takeLatest(types.REQ_DUPLICATE_CONTENTS, function*({ payload }) {
    try {
      yield call(ContentsAPI.duplicateItem, payload)
      yield put(contentsActions.reqDuplicateContentsDone())
      yield put(push('/contents'))
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqDuplicateContentsFail(e))
    }
  })
}

function* listInsuranceTypes() {
  yield takeLatest(types.REQ_LIST_INSURANCE_TYPES, function*({ payload }) {
    const { contentsId } = payload
    try {
      const res = yield call(ContentsAPI.getInsuranceTypes, contentsId)

      yield put(
        contentsActions.reqListInsuranceTypesDone({
          insuranceTypes: res.data,
        })
      )
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqListInsuranceTypesFail(e))
    }
  })
}

function* updateInsuranceTypes() {
  yield takeLatest(types.REQ_UPDATE_INSURANCE_TYPES, function*({ payload }) {
    const { contentsId, insuranceTypes } = payload
    try {
      const res = yield call(
        ContentsAPI.updateInsuranceTypes,
        contentsId,
        insuranceTypes
      )

      yield put(
        contentsActions.reqListInsuranceTypesDone({
          insuranceTypes: res.data,
        })
      )
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqListInsuranceTypesFail(e))
    }
  })
}

function* updateApplyView() {
  yield takeLatest(types.REQ_UPDATE_APPLY_VIEW, function*({ payload }) {
    const { contentsId, insuranceTypes, consultImgFile, consultImg } = payload
    try {
      // 보험타입 수정
      yield call(ContentsAPI.updateInsuranceTypes, contentsId, insuranceTypes)

      let uploadedFileUrl = null

      // 파일이 전달되었으면 업로드
      if (consultImgFile) {
        uploadedFileUrl = yield call(FileAPI.upload, {
          file: consultImgFile,
          eid: contentsId,
        })
      }

      // consultImg는 업로드된 이미지 URL일수도 있고, Blob string일수도 있다.
      // 컨텐츠에는 URL이 들어가야 함.
      yield call(ContentsAPI.updateItem, {
        id: contentsId,
        reqBody: {
          consult_img: uploadedFileUrl || consultImg || '', // 빈 문자열을 전송해야 수정됨
        },
      })

      yield put(contentsActions.reqUpdateApplyViewDone())
      yield put(
        modalActions.showAlert({
          i18nKey: '저장되었습니다',
        })
      )
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqUpdateApplyViewFail(e))
    }
  })
}

function* readMaxContents() {
  yield takeLatest(types.REQ_READ_MAX_CONTENTS, function*({ payload }) {
    try {
      const maxContents = yield call(ContentsAPI.getMaxContents)
      yield put(contentsActions.reqReadMaxContentsDone(maxContents))
    } catch (e) {
      console.error(e)
      yield put(contentsActions.reqReadMaxContentsFail(e))
    }
  })
}

/**
 * 컨텐츠 데이터 업데이트. delay effect로 debounce 효과를 준다
 */
function* setContentsReqBody() {
  yield takeLatest(types.SET_CONTENTS_REQ_BODY, function*({ payload }) {
    yield delay(200)
    yield put(contentsActions.setContentsReqBodyDebounced(payload))
  })
}

export function* contentsSaga(): Generator<any, any, any> {
  yield all([
    listContents(),
    readContents(),
    createContents(),
    updateContents(),
    deleteContents(),
    duplicateContents(),
    listInsuranceTypes(),
    updateInsuranceTypes(),
    updateApplyView(),
    readMaxContents(),
    setContentsReqBody(),
  ])
}
