import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { createGlobalStyle } from 'styled-components'
import { cdnImage } from 'utils/string'

import styled from 'styled-components'

const SlideImage = styled.div`
  display: block;
  background: url(${({ url }) => url}) no-repeat center;
  background-size: contain;
  width: 300px;
  height: 190px;
  object-fit: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 40px auto;
`

// override stylesheet of react-slick
export const PreviewSliderStyle = createGlobalStyle`
.previewSlider {
  .slick-prev,
  .slick-next {
    width: 20px;
    height: 30px;

    &:before {
      content: initial;
    }
  }

  .slick-prev,
  .slick-prev:focus,
  .slick-prev:hover {
    background: url(${cdnImage('slide-prev.png')}) no-repeat center;
    background-size: contain;
  }
  .slick-next,
  .slick-next:focus,
  .slick-next:hover {
    background: url(${cdnImage('slide-next.png')}) no-repeat center;
    background-size: contain;
  }

}

.previewSliderDots {
  display: block;
  width: 100%;
  padding: 20px;
  margin: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 2.5px;
    padding: 0;
    cursor: pointer;


    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 10px;
      height: 10px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: #6e6e6e;
      border-radius: 50%;
    }

    &.slick-active {
      button {
        background: #0055ec;
      }
    }
  }
}
`

const Wrap = styled.div`
  margin-top: 20px;
  border: solid 1px #dee0e4;
  background-color: #f4f4f4;
`

const Slide = styled.div`
  padding: 70px 20px 0;

  h3 {
    font-size: 22px;
    font-weight: normal;
    color: #1f5de6;
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 2;
  }
`

export default () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'previewSlider',
    dotsClass: 'previewSliderDots',
  }

  const slideDatas = [
    {
      title: '샘플콘텐츠가 준비되어 있습니다.',
      image: cdnImage('register-done-tips-1.png'),
      explanation: `바로 이용하실 수 있는 샘플콘텐츠가 제공됩니다. <br />
      그대로 사용하실 수도 있고, 나에게 맞게 적절히 수정하실 수도
      있어요.`,
    },
    {
      title: '고객DB를 한 군데서 확인하실 수 있습니다.',
      image: cdnImage('register-done-tips-2.png'),
      explanation: `상단 메뉴의 '고객DB'에서 모든 콘텐츠를 통해 <br/>
      상담신청한 고객DB를 한번에 보실 수 있습니다.`,
    },
    {
      title: '콘텐츠를 쉽게 뿌리고 전달하세요.',
      image: cdnImage('register-done-tips-4.png'),
      explanation: `콘텐츠 리스트나 상세화면에서 항상 콘텐츠 주소가 표시됩니다.<br/>
      복사하기 버튼을 눌러 카카오톡에 공유하시거나, 블로그, 페이스북에 게시해주세요.<br/>
      물론 문자메시지에도 붙여보낼 수 있습니다.`,
    },
  ]

  return (
    <Wrap>
      <Slider {...settings}>
        {slideDatas.map((slide, index) => (
          <Slide key={index}>
            <h3>{slide.title}</h3>
            <SlideImage url={slide.image} />
            <p dangerouslySetInnerHTML={{ __html: slide.explanation }} />
          </Slide>
        ))}
      </Slider>
    </Wrap>
  )
}
